<template>
  <div
    data-cy="c-input"
    class="base-input"
    :class="{
      'base-input--focus': focused,
      'base-input--custom-field': customField,
    }"
    @click="setFocus"
  >
    <div
      v-if="hasSlot('input-prepend')"
      class="base-input__prepend"
    >
      <slot name="input-prepend" />
    </div>
    <slot
      name="input"
      :value="inputValue"
      :input="debounceInput"
    >
      <input
        :id="id"
        ref="input"
        :value="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="inputClasses"
        :type="inputType"
        :min="min"
        :max="max"
        :data-cy="dataCy"
        class="base-input__field form-control"
        @input="debounceInput"
        @focus="focusIn"
        @blur="blur"
        @keydown="keysHandler"
        @change="onChangeValue"
      >
    </slot>
    <div
      v-if="hasSlot('input-append') || showClearButton"
      class="base-input__append"
    >
      <slot name="input-append">
        <a
          v-show="value"
          data-cy="clear-input-button"
          href="javascript:"
          @click.prevent="$emit('input', '')"
        >
          <svg-icon
            name="close"
            size="12"
          />
        </a>
      </slot>
    </div>
  </div>
</template>

<script>
import MixinSlots from '@/mixins/MixinSlots';

export default {
  name: 'base-input',
  mixins: [
    MixinSlots,
  ],
  props: {
    value: { type: String },
    id: { type: String, default: null },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    customField: { type: Boolean, default: false },
    size: { type: String, default: '' },
    type: { type: String, default: 'text' },
    formatter: { type: Function },
    inputType: { type: String, default: 'text' },
    min: { type: Number },
    max: { type: Number },
    dataCy: { type: String, default: 'input' },
    showClearButton: { type: Boolean, default: false },
    debounce: { type: Number, default: 0 },
  },
  data() {
    return {
      localValue: this.value ?? '',
      focused: false,
      debounceInput: () => {},
      inputEl: undefined,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value !== undefined ? this.value : this.localValue;
      },
      set(value) {
        this.$emit("input", value);
        this.localValue = value;
      },
    },
    inputClasses() {
      return {
        'form-control--custom-field': this.customField,
        [`form-control-${this.size}`]: this.size,
      };
    },
  },
  beforeMount() {
    this.debounceInput = _.debounce(this.input, this.debounce);
  },
  mounted() {
    this.$input = this.$refs.input;
  },
  methods: {
    setFocus() {
      this.$refs.input.focus();
    },
    input(evt) {
      const element = evt.target;

      if (this.type === 'number') {
        const value = element.value.replace(',', '.');

        if (/^-?[0-9]*\.?[0-9]*$/.test(value)) {
          element.value = value;
          this.$emit('input', value, evt);
        } else {
          element.value = this.inputValue.toString();
        }

        return;
      }

      let value = element.value || '';

      if (this.formatter && value) {
        value = element.value = this.formatter(value);
      }

      this.$emit('input', value, evt);
    },
    onChangeValue(evt) {
      const element = evt.target;

      if (this.type === 'number') {
        const parsedNumber = parseFloat(element.value);
        const strNumber = !Number.isNaN(parsedNumber) ? parsedNumber.toString() : '';

        if (strNumber && strNumber !== element.value.toString()) {
          element.value = strNumber;
          this.$emit('input', strNumber, evt);
        }
      }

      this.$emit('change', evt);
    },
    focusIn(evt) {
      this.focused = true;
      this.$emit('focus', evt);
    },
    blur(evt) {
      if (evt.target.value !== this.inputValue) {
        this.debounceInput.flush(evt);
      }

      this.focused = false;
      this.$emit('blur', evt);
    },
    keysHandler(evt) {
      const val = evt.target.value;

      if ((val === '' || / $/.test(val)) && (evt.code === 'Space' || evt.keyCode === 32)) {
        evt.preventDefault();
      } else if (evt.code === 'Enter' || evt.keyCode === 13) {
        // evt.preventDefault();
        this.$emit('enter', evt);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/components/base-input';
</style>
