<template>
  <ul
    class="collapsible-list"
    :class="{ 'collapsible-list--no-content-slots': !hasContentSlot }"
  >
    <slot
      v-for="item in items"
      :name="`li(${item.key})`"
      :item="item"
    >
      <li
        :key="item.key"
        :class="item.class"
        class="collapsible-list__item"
      >
        <collapsible-list-item
          v-model="checkedValue"
          class="collapsible-list__item-content"
          :item="item"
          :is-selectable="isSelectable"
          @shown="$emit('shown', $event)"
        >
          <loading
            v-if="loadingItems[item.key]"
            height="150"
          />
          <slot
            v-else-if="hasSlot(`item(${item.key})`)"
            :name="`item(${item.key})`"
            :item="item"
          />
          <slot
            v-else
            :item="item"
          />
        </collapsible-list-item>
      </li>
    </slot>
  </ul>
</template>

<script>
import CollapsibleListItem from "@/components/collapsible-list/CollapsibleListItem";
import MixinSlots from "@/mixins/MixinSlots";

export default {
  name: 'collapsible-list',
  components: {
    CollapsibleListItem,
  },
  mixins: [
    MixinSlots,
  ],
  props: {
    loadingItems: {
      type: Object,
      default: Object,
    },
    items: {
      type: Array,
      default: Array,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: Array,
    },
  },
  computed: {
    checkedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    hasContentSlot() {
      const hasItemsSlot = this.items.some(item => this.hasSlot(`item(${item.key})`));
      return hasItemsSlot || this.hasSlot();
    },
  },
};
</script>

<style lang="scss" scoped>
.collapsible-list {
  list-style: none;
  margin: 0;
  padding: 0;

  &--no-content-slots::v-deep {
    .item-info__icon {
      display: none;
    }
  }
}
</style>
