<template>
  <b-pagination
    first-number
    :disabled="disabled"
    :value="currentPage"
    :total-rows="totalRows"
    :per-page="perPage"
    last-class="d-none"
    hide-ellipsis
    @change="onChangePage"
  >
    <template v-slot:first-text>
      <svg-icon
        name="chevron-2-left"
        size="10"
      />
    </template>
    <template v-slot:prev-text>
      <svg-icon
        name="chevron-left"
        size="10"
      />
    </template>
    <template v-slot:next-text>
      <svg-icon
        name="chevron-right"
        size="10"
      />
    </template>
    <template v-slot:last-text>
      <svg-icon
        name="chevron-2-right"
        size="10"
      />
    </template>
  </b-pagination>
</template>

<script>
export default {
  name: "base-pagination",
  props: {
    disabled: { type: Boolean, default: false },
    currentPage: { type: Number, default: 1 },
    totalRows: { type: Number, default: 1 },
    perPage: { type: Number, default: 1 },
  },
  methods: {
    onChangePage(pageNumber) {
      this.$emit('change', pageNumber);
    },
  },
}
</script>

<style scoped>

</style>
