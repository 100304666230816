<script>
export default {
  name: "InfoBadge",
  props: {
    title: { type: String, default: '' },
    variant: { type: String, default: '' },
    type: { type: String, default: '' },
  },
  computed: {
    badgeClass() {
      return this.variant ? `info-badge--${this.variant}` : '';
    },
  },
};
</script>

<template>
  <div
    v-if="type === 'label'"
    :class="['info-badge info-badge--label', badgeClass]"
  >
    <div class="info-badge__text">
      {{ title }}
    </div>
  </div>
  <div
    v-else
    v-b-tooltip
    :title="title"
    :class="['info-badge info-badge--icon', badgeClass]"
  >
    <svg-icon
      class="info-badge__icon"
      name="status-new-bold"
    />
  </div>
</template>

<style scoped lang="scss">
.info-badge {
  $block: &;

  --variant-color: var(--text-color);

  &__icon {
    --size: 16px;
  }

  &--secondary {
    --variant-color: var(--secondary);
  }

  &--warning {
    --variant-color: var(--warning);
  }

  &--label {
    #{$block}__text {
      background-color: var(--variant-color);
      color: #fff;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 0 2px;
      display: inline;
    }
  }

  &--icon {
    color: var(--variant-color);
  }
}
</style>
