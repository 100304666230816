<template>
  <div class="entity-table">
    <data-table
      :fixed-header="fixedHeader"
      :busy="isLoading || busy"
      :items="tableItems"
      :fields="tableFields"
      :sort="sort"
      :sort-direction="sortDirection"
      :tbody-tr-class="getTbodyTrClass"
      :striped="striped"
      :fixed-required-columns="fixedRequiredColumns"
      :dataset="dataset"
      show-empty
      class="entity-table__table l-table"
      @sort-changed="onChangeSort"
    >
      <!-- Head -->
      <template
        v-for="(field, index) in tableFields"
        v-slot:[`head(${field.key})`]="data"
      >
        <template v-if="field.key === 'checkbox' && selectable">
          <b-form-checkbox
            :key="index"
            :checked="isAllSelected"
            :indeterminate="isIndeterminate"
            :disabled="!itemsIds.length"
            @change="onSelectedAll"
          />
        </template>
        <slot
          v-else
          v-bind="data"
          :name="`head(${field.key})`"
        >
          <span class="entity-table-head">
            <span class="entity-table-head__label">{{ data.label }}</span>
          </span>
        </slot>
      </template>

      <template v-slot:row="data">
        <slot
          name="row"
          v-bind="data"
          :possible-actions="actionsList"
          :actions="getItemActions(data.item)"
          :is-loading="loadingItems.includes(data.item.id)"
          :action-handler="onItemAction"
        />
      </template>

      <!-- Columns -->
      <template
        v-for="(field, index) in tableFields"
        v-slot:[`cell(${field.key})`]="data"
      >
        <slot
          v-bind="data"
          :name="`cell(${field.key})`"
          :list-index="getListIndex(data)"
        >
          <template v-if="field.key === 'checkbox' && selectable">
            <b-form-checkbox
              :checked="selectedItems"
              :value="data.item.id"
              @change="onSelected"
            />
          </template>
          <template v-else-if="field.key === 'actions'">
            <actions-field
              :is-loading="loadingItems.includes(data.item.id)"
              :possible-actions="actionsList"
              :actions="getItemActions(data.item)"
              :horizontal-dots="actionsDotsHorizontal"
              @action="onItemAction(data.item, $event, data.index)"
            />
          </template>
          <template v-else-if="field.key === 'details'">
            <b-link
              class="toggle-details"
              :class="{ active: Boolean(data.item._showDetails) }"
              @click="data.toggleDetails"
            >
              <svg-icon
                size="8"
                name="arrow-down-sm"
              />
            </b-link>
          </template>
          <template v-else-if="field.key === 'row_number'">
            {{ getRowNumber(data.index) }}
          </template>
          <template v-else-if="!hasSlot(`cell(${field.key})`)">
            {{ data.value }}
          </template>
        </slot>
      </template>

      <template
        v-if="detailsFields.length > 0"
        v-slot:row-details="data"
      >
        <columns-info
          :fields="detailsFields"
          :item="data.item"
          :main-field="mainField"
          :columns="2"
          :dataset="dataset"
          show-empty-fields
        >
          <template
            v-for="(field, index) in detailsSlots"
            v-slot:[field.key]="data"
          >
            <slot
              v-bind="data"
              :name="`cell(${field.key})`"
            />
          </template>
        </columns-info>
      </template>

      <!-- Table Busy state -->
      <template v-slot:table-busy>
        <loading height="200"></loading>
      </template>

      <!-- Table Empty state -->
      <template v-slot:empty>
        <div class="l-table__empty">
          {{ $vDict('global.list_no_items.text') }}
        </div>
      </template>
    </data-table>

    <!-- Pagination -->
    <div
      v-if="showPagination && perPage < tableTotalRows && items.length > 0"
      class="entity-table__pagination"
    >
      <base-pagination
        :disabled="isLoading"
        :total-rows="tableTotalRows"
        :per-page="perPage"
        :current-page="tableCurrentPage"
        @change="changeCurrentPage"
      />
    </div>
  </div>
</template>
<script>
import DataTable from '@/components/table/DataTable';
import MixinSlots from '@/mixins/MixinSlots';
import BasePagination from "@/components/BasePagination";
import ActionsField from "@/components/table/fields/ActionsField.vue";

const ColumnsInfo = () => import('@/components/ColumnsInfo');

const fieldActions = {
  key: 'actions',
  label: '',
  class: 'column-actions',
  dataCy: 'row-actions',
};

const fieldDetails = {
  key: 'details',
  label: '',
  class: 'column-details',
  dataCy: 'row-details',
};

const fieldRowNumber = {
  key: 'row_number',
  label: '#',
  class: 'column-min column-row-number',
  required: true,
  dataCy: 'row-number',
};

const fieldCheckbox = {
  key: 'checkbox',
  class: 'column-min',
  required: true,
  dataCy: 'checkbox',
};

const defaultActions = {
  view: {
    key: 'view',
    name: 'View',
    icon: 'pwd-show',
  },
  edit: {
    key: 'edit',
    name: 'Edit',
    icon: 'edit',
  },
  delete: {
    key: 'delete',
    name: 'Delete',
    icon: 'trash',
    linkClass: 'action-link--warning',
  },
};

export default {
  name: 'entity-table',
  components: {
    DataTable,
    BasePagination,
    ColumnsInfo,
    ActionsField,
  },
  mixins: [
    MixinSlots,
  ],
  props: {
    isLoading: { type: Boolean, default: false },
    fields: { type: Array, default: Array },
    items: { type: Array, default: Array },
    sort: { type: Object, default: Object },
    perPage: { type: Number, default: Number(process.env.VUE_APP_ITEMS_PER_PAGE) },
    actions: { type: Array, default: Array },
    currentPage: { type: Number, default: 1 },
    tbodyTrClass: { type: Function, default: () => '' },
    loadingItems: { type: Array, default: Array },
    rowNumber: { type: Boolean, default: false },
    fixedHeader: { type: Boolean, default: false },
    isStaticItems: { type: Boolean, default: false },
    striped: { type: Boolean, default: true },
    totalRows: { type: Number, default: null },
    actionsDotsHorizontal: { type: Boolean, default: false },
    fixedRequiredColumns: { type: Boolean, default: false },
    showPagination: { type: Boolean, default: false },
    dataset: { type: Object, default: Object },
    filterActions: { type: Function },
    selectable: { type: Boolean, default: false },
    selectedItems: { type: Array, default: Array },
  },
  data() {
    return {
      busy: false,
      syncSortBy: null,
      syncSortDesc: false,
      sortDirection: 'd',

      // for static items
      localCurrentPage: 1,
    };
  },
  computed: {
    tableFields() {
      const fields = this.fields.filter(field => !field.details);

      if (this.detailsFields.length > 0 &&
        this.fields.every(field => field.key !== fieldDetails.key)) {
        fields.push(fieldDetails);
      }

      if (this.actions.length > 0 &&
        this.fields.every(field => field.key !== fieldActions.key)) {
        fields.push(fieldActions);
      }

      if (this.rowNumber) {
        fields.unshift(fieldRowNumber);
      }

      if (this.selectable) {
        fields.unshift(fieldCheckbox);
      }

      return fields;
    },
    actionsList() {
      return this.actions.map(action => ({
        ...(defaultActions[action.key] || {}),
        ...action,
      }));
    },
    detailsFields() {
      return this.fields.filter(field => field.details);
    },
    detailsSlots() {
      return this.detailsFields.filter(field => this.hasSlot(`cell(${field.key})`));
    },
    mainField() {
      return this.tableFields.find(el => el.main);
    },
    tableCurrentPage() {
      return this.isStaticItems ? this.localCurrentPage : this.currentPage;
    },
    tableItems() {
      let items = [];

      if (this.isStaticItems && this.perPage > 0 && this.localCurrentPage > 0) {
        const fromIndex = (this.localCurrentPage - 1) * this.perPage;
        const toIndex = fromIndex + this.perPage;

        items = this.items.slice(fromIndex, toIndex);
      } else {
        items = this.items;
      }

      return items;
    },
    tableItemsCount() {
      return this.tableItems.length;
    },
    tableTotalRows() {
      return this.totalRows ?? this.items.length;
    },
    itemsIds() {
      return this.items.map(item => item.id);
    },
    // selectable
    isAllSelected() {
      const diff = _.difference(this.itemsIds, this.selectedItems);
      return this.selectedItems.length > 0 && !diff.length;
    },
    isIndeterminate() {
      return !this.isAllSelected && this.selectedItems.length > 0;
    },
  },
  watch: {
    tableItemsCount(count) {
      if (!this.isStaticItems) {
        return;
      }

      if (count === 0 && this.localCurrentPage > 1) {
        const page = Number(this.items.length / this.perPage);
        this.changeCurrentPage(page);
      }
    },
  },
  methods: {
    getTbodyTrClass(item) {
      const classes = [];

      if (this.tbodyTrClass) {
        classes.push(this.tbodyTrClass(item));
      }

      if (this.selectable && this.selectedItems.includes(item.id)) {
        classes.push('dt-table__tr--selected');
      } else if (typeof item.isLocked === 'function' && item.isLocked()) {
        classes.push('dt-table__tr--locked');
      } else if (typeof item.isDeleted === 'function' && item.isDeleted()) {
        classes.push('dt-table__tr--deleted');
      }

      return classes;
    },
    getListIndex(data) {
      const itemIndex = data.index ?? 0;
      return itemIndex + (this.tableCurrentPage - 1) * this.perPage;
    },
    getItemActions(item) {
      let actions = item.getActions ? item.getActions(this.dataset) : this.actionsList;

      if (this.filterActions) {
        actions = this.filterActions(item, actions);
      }

      return actions.map(action => ({
        ...action,
        linkAttrs: {
          ...(action.linkAttrs || {}),
          'data-cy': `action-link-${action.dataCy || action.key}`,
          href: action.getLink ? action.getLink(item) : undefined,
        },
      }));
    },
    onChangeSort(sort) {
      this.$emit('sort-changed', sort);
    },
    onItemAction(item, action, itemIndex) {
      this.$emit('action', {
        action,
        item,
        itemIndex,
      });
    },
    getRowNumber(itemIndex) {
      const itemNumber = itemIndex + 1;
      const pageIndex = this.tableCurrentPage - 1;
      return (pageIndex * this.perPage) + itemNumber;
    },
    changeCurrentPage(page = 1) {
      if (this.isStaticItems) {
        this.busy = true;

        setTimeout(() => {
          this.localCurrentPage = page;
          this.busy = false;
        }, 500);
        return;
      }

      this.$emit('change-page', page);
    },
    // selectable
    isSelected(itemId) {
      return this.selectedItems.includes(itemId);
    },
    onSelected(ids = []) {
      this.$emit('select', ids);
    },
    onSelectedAll(checked) {
      const res = checked ? this.itemsIds : [];
      this.$emit('select', res);
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-table {
  .l-table {
    &__empty {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &::v-deep {
    .table.b-table > thead > tr > th[aria-sort],
    .table.b-table > tfoot > tr > th[aria-sort] {
      background: none;

      &::after {
        content: normal;
      }
    }

    .dropdown-actions {
      .dropdown-menu {
        width: 208px;
      }

      .dropdown-item {
        color: inherit;
      }

      .dropdown-toggle {
        padding: 0 8px;
      }
    }

    .toggle-details {
      display: inline-flex;
      width: 16px;
      height: 16px;
      border: 2px solid var(--secondary);
      color: var(--secondary);
      background-color: #fff;
      border-radius: 16px;
      align-items: center;
      justify-content: center;

      &.active {
        background-color: var(--secondary);
        color: #fff;
        transform: rotate(180deg);
      }
    }

    .dt-table__tr {
      &--selected {
        --row-bg: var(--entity-list-table-selected-even-row-bg);
        --odd-row-bg: var(--entity-list-table-selected-odd-row-bg);
      }

      &--locked {
        --row-bg: var(--entity-list-table-locked-entity-even-row-bg);
        --odd-row-bg: var(--entity-list-table-locked-entity-odd-row-bg);
      }

      &--deleted {
        --row-bg: var(--entity-list-table-deleted-entity-even-row-bg);
        --odd-row-bg: var(--entity-list-table-deleted-entity-odd-row-bg);
      }
    }
  }

  .th-label {
    display: flex;
    align-items: flex-start;

    .svg-icon {
      display: inline-block;
      flex-shrink: 0;
      margin-top: -2px;
      margin-right: 5px;
    }

    &__body {
      align-self: center;
    }

    &__text {
      display: inline;
      padding-top: 2px;
    }

    &__sort-icon {
      display: inline;
      white-space: nowrap;
      color: var(--table-sort-icon-color);

      .svg-icon {
        display: inline-block;
        margin-top: 0;
        margin-right: 0;
      }
    }

    .svg-icon + &__sort-icon {
      display: block;
      align-self: center;
      margin-left: 0;
    }
  }

  &__pagination {
    padding-top: 24px;
    /*border-top: 1px solid var(--line-color-main);*/
    display: flex;

    .pagination {
      margin-left: auto;
      margin-bottom: 0;
    }
  }

  /*.list-item {*/
  /*  &__action {*/
  /*    margin-left: 10px;*/

  /*    &:first-child {*/
  /*      margin-left: 0px;*/
  /*    }*/
  /*  }*/
  /*}*/

  .entity-table-head {
    &__label {
      margin-right: 4px;
    }
  }
}
</style>
