<template>
  <div class="columns-info">
    <ul
      v-for="(column, columnIndex) in listColumns"
      :key="columnIndex"
      class="list-info list-info--label-column list-info--striped list-info--no-justify"
    >
      <li
        v-for="field in column"
        :key="field.key"
        class="list-info__item"
      >
        <div class="list-info__label">{{ field.label }}:</div>
        <div class="list-info__value">
          <slot
            :name="field.key"
            :item="item"
            :field="field"
            :value="field.value"
          >
            <template v-if="field.isShowModalLink">
              <div class="ci-field-shortened">
<!--                <div
                  v-if="field.shortValue"
                  class="ci-field-shortened__text"
                >
                  {{ field.shortValue }}
                </div>-->
                <div class="ci-field-shortened__link">
                  <b-link
                    @click="showMoreDetails(field, item)"
                  >
                    <svg-icon
                      size="14"
                      name="popup"
                    />
                  </b-link>
                </div>
              </div>
            </template>
            <template v-else-if="field.isEmptyValue">—</template>
            <template v-else>{{ field.value }}</template>
          </slot>
        </div>
      </li>
    </ul>

    <modal-field-details
      v-if="showModalDetails"
      :show-modal.sync="showModalDetails"
      :field-data="modalDetailsData.field"
      :item="modalDetailsData.item"
      :id-text="idText"
    />
  </div>
</template>

<script>
import { isEmptyValue, isJSON } from '@/shared/utils';

const ModalFieldDetails = () => import('@/components/modals/ModalFieldDetails');

export default {
  name: 'columns-info',
  components: {
    ModalFieldDetails,
  },
  props: {
    columns: { type: Number, default: 1 },
    fields: { type: Array, default: Array },
    item: { type: Object, default: Object },
    mainField: { type: Object, default: null },
    showEmptyFields: { type: Boolean, default: false },
    dataset: { type: Object, default: Object },
  },
  data() {
    return {
      enums,
      modalDetailsData: {},
      showModalDetails: false,
    };
  },
  computed: {
    listFields() {
      const fields = this.fields.map(field => {
        const fieldValue = field.value || this.getFieldValue(field);
        const strFieldValue = JSON.stringify(fieldValue) || '';
        const fieldData = {
          ...field,
          label: field.label || field.key,
          value: fieldValue,
        };

        // if (fieldData.type === enums.TABLE_FIELD_TYPES.JSON) {
        //   fieldData.shortValue = fieldData.value ? fieldData.value.substr(0, 100) : '';
        //
        //   if (!this.showEmptyFields && !isJSON(fieldData.value)) {
        //     fieldData.type = null;
        //   }
        // }

        return {
          ...fieldData,
          isShowModalLink: field.hasOwnProperty('asyncData') || strFieldValue.length > 100,
          isEmptyValue: this.isEmptyValue(fieldValue),
        };
      });

      return !this.showEmptyFields ? fields.filter(field => !field.isEmptyValue) : fields;
    },
    listColumns() {
      const chunks = Math.ceil(this.listFields.length / this.columns);
      return _.chunk(this.listFields, chunks);
    },
    idText() {
      return this.mainField ? this.getFieldValue(this.mainField) : this.item.id;
    },
  },
  watch: {
    showModalDetails(isShow) {
      if (!isShow) {
        this.modalDetailsData = {};
      }
    },
  },
  methods: {
    isEmptyValue(val) {
      const resVal = isJSON(val) ? JSON.parse(val) : val;
      return isEmptyValue(resVal);
    },
    getFieldValue(field) {
      let value;

      if (this.item.type === 'entity') {
        value = this.item.getFormattedValue(field.key, this.dataset, this.fields);
      } else {
        value = this.item[field.key];
      }

      return field.formatter ? field.formatter(value, field.key, this.item) : value;
    },
    showMoreDetails(field, item) {
      this.modalDetailsData = { field, item };
      this.showModalDetails = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.ci-field-shortened {
  display: flex;

  &__text {
    flex: 1;
    margin-right: 6px;
  }
  &__link {
    flex: none;

    a {
      color: var(--secondary);
    }
  }
}

.info-title {
  font-weight: bold;
}

.info-field {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 5px;
  }
}
</style>
