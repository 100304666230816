<template>
  <div
    class="base-switcher"
    :class="classComputed"
    @click="trigger"
  >
    <button
      ref="icon"
      type="button"
      class="base-switcher__icon"
      :disabled="disabled"
      @focus="onFocus"
      @blur="onBlur"
    >
      <div class="base-switcher__icon-handle" />
    </button>

    <div
      v-if="hasLabel"
      class="base-switcher__label"
    >
      <slot>{{ label }}</slot>
    </div>
  </div>
</template>

<script>
const STATES = {
  UNCHECKED: 0,
  POSITIVE: 1,
  NEGATIVE: -1,
  INDETERMINATE: 2,
};

export default {
  name: 'base-switcher',
  model: {
    prop: 'checked',
    event: ['input', 'change'],
  },
  props: {
    value: { default: null },
    checked: { default: null },
    twoPoints: { type: Boolean, default: false },
    indeterminate: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
    trueValue: { default: true },
    trueValueExclude: { default: -1 },
    falseValue: { default: false },
    right: { type: Boolean, default: false },
  },
  data() {
    return {
      state: this.checked ? Number(this.checked) : STATES.UNCHECKED,
      focused: false,
    };
  },
  computed: {
    computedChecked: {
      get() {
        return this.state;
      },
      set(val) {
        this.state = val;
      },
    },
    hasLabel() {
      return this.label !== '' || this.hasSlot('default');
    },
    classComputed() {
      return {
        disabled: this.disabled,
        'base-switcher--two-points': this.twoPoints,
        checked: this.state === STATES.POSITIVE,
        'checked-2': this.twoPoints && this.state === STATES.NEGATIVE,
        indeterminate: this.indeterminate,
        focused: this.focused,
        'base-switcher--right': this.right,
      };
    },
    cTrueValue() {
      return (this.twoPoints && this.trueValue === true) ? 1 : this.trueValue;
    },
    cFalseValue() {
      return (this.twoPoints && this.falseValue === false) ? 0 : this.falseValue;
    },
  },
  watch: {
    checked: {
      handler(val) {
        if (val instanceof Array) {
          if (val.includes(this.value * -1)) {
            this.state = STATES.NEGATIVE;
          } else if (val.includes(this.value)) {
            this.state = STATES.POSITIVE;
          } else {
            this.state = STATES.UNCHECKED;
          }
        } else if (val === this.cTrueValue) {
          this.state = STATES.POSITIVE;
        } else if (val === this.trueValueExclude) {
          this.state = STATES.NEGATIVE;
        } else {
          this.state = STATES.UNCHECKED;
        }
      },
      immediate: true,
    },
    indeterminate: {
      handler(val) {
        if (val) {
          this.state = STATES.UNCHECKED;
        }
      },
      immediate: true,
    },
  },
  methods: {
    hasSlot(slotName) {
      return !!this.$slots[slotName] || !!this.$scopedSlots[slotName];
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    trigger() {
      let newValue;
      if (this.disabled) return;

      switch (this.state) {
        case STATES.UNCHECKED:
          this.state = STATES.POSITIVE;
          break;
        case STATES.POSITIVE:
          if (this.twoPoints) {
            this.state = STATES.NEGATIVE;
          } else {
            this.state = STATES.UNCHECKED;
          }
          break;
        case STATES.NEGATIVE:
          this.state = STATES.UNCHECKED;
          break;
        default:
      }

      this.focused = true;
      if (this.$refs && this.$refs.icon) {
        this.$refs.icon.focus();
      }

      if (this.checked instanceof Array) {
        newValue = [...this.checked];
        if (!this.twoPoints) {
          if (this.state === STATES.POSITIVE) {
            newValue.push(this.value);
          } else {
            newValue.splice(newValue.indexOf(this.value), 1);
          }
        } else {
          if (this.state === STATES.POSITIVE) {
            newValue.push(this.value);
          } else if (newValue.includes(this.value)) {
            newValue.splice(newValue.indexOf(this.value), 1);
          }

          if (this.state === STATES.NEGATIVE) {
            newValue.push(-this.value);
          } else if (newValue.includes(-this.value)) {
            newValue.splice(newValue.indexOf(-this.value), 1);
          }
        }
      } else if (this.state === STATES.POSITIVE) {
        newValue = this.cTrueValue;
      } else if (this.state === STATES.NEGATIVE) {
        newValue = this.trueValueExclude;
      } else {
        newValue = this.cFalseValue;
      }
      // console.log('newValue', newValue);
      this.$emit('input', newValue);
      this.$emit('change', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-switcher {
  cursor: pointer;
  display: flex;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, opacity;
  padding: 6px 8px;

  &.disabled {
    opacity: .6;
    cursor: default;
  }

  &__icon {
    position: relative;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 2px 0;
    background: var(--input-bg, $input-bg);
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    border: 1px solid var(--input-border-color, $input-border-color);
    border-radius: 3px;
    transition: 0.15s ease-in-out;
    transition-property: border-color;

    &:focus {
      border-color: var(--input-focus-border-color, $input-focus-border-color);
      box-shadow: $focus-shadow-options var(--focus-shadow-color, $focus-shadow-color);
    }

    .indeterminate &,
    .checked & {
      border-color: var(--primary, $primary);
    }

    .base-switcher--two-points & {
      width: 28px;
      height: 20px;
      padding: 9px 0;
      margin: 0;
      background: none;
      border: 0;

      &:focus {
        box-shadow: none;
      }
    }

    .base-switcher--right & {
      order: 100;
    }

    &::before {
      .base-switcher--two-points & {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-image: none;
        background-color: var(--base-switcher-track-color, $base-switcher-track-color);
        transition: 0.3s ease-in-out;
        transition-property: background-color, border-color;
      }

      .base-switcher--two-points.indeterminate & {
        background-color: var(--base-switcher-track-indeterminate-color, $base-switcher-track-indeterminate-color);
      }

      .base-switcher--two-points.checked & {
        background-color: var(--base-switcher-track-checked-color, $base-switcher-track-checked-color);
      }

      .base-switcher--two-points.checked-2 & {
        background-color: var(--base-switcher-track-checked-2-color, $base-switcher-track-checked-2-color);
      }
    }
  }

  &__icon-handle {
    width: 100%;
    height: 100%;
    background: no-repeat 50% / 8px 8px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-color: var(--primary, $primary);
    opacity: 0;
    transition: 0.15s ease-in-out;
    transition-property: opacity;

    .checked & {
      opacity: 1;
    }

    .indeterminate & {
      background-color: var(--primary, $primary);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
      opacity: 1;
    }

    .base-switcher--two-points & {
      position: absolute;
      top: 3px;
      left: calc(50% - 7px);
      width: 14px;
      height: 14px;
      border: var(--base-switcher-handle-border, $base-switcher-handle-border) var(--base-switcher-handle-unchecked-border-color, $base-switcher-handle-unchecked-border-color);
      border-radius: 14px;
      background-image: none;
      background-color: var(--base-switcher-handle-unchecked-bg, $base-switcher-handle-unchecked-bg);
      color: var(--base-switcher-handle-color, $base-switcher-handle-color);
      opacity: 1;
      transition: 0.3s ease-in-out;
      transition-property: left, background-color, border-color, box-shadow;
    }

    .base-switcher--two-points .base-switcher__icon:focus & {
      box-shadow: $focus-shadow-options var(--focus-shadow-color, $focus-shadow-color);
    }

    &::before {
      .base-switcher--two-points & {
        content: '';
        display: block;
        background-color: currentColor;
        width: 8px;
        height: 2px;
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% - 4px);
        display: none;
      }

      .base-switcher--two-points.checked &,
      .base-switcher--two-points.checked-2 & {
        display: block;
      }
    }

    &::after {
      .base-switcher--two-points & {
        content: '';
        display: block;
        background-color: currentColor;
        width: 2px;
        height: 8px;
        position: absolute;
        top: calc(50% - 4px);
        left: calc(50% - 1px);
        display: none;
      }

      .base-switcher--two-points.checked & {
        display: block;
      }
    }

    .base-switcher--two-points.indeterminate & {
      background-color: var(--base-switcher-handle-indeterminate-bg, $base-switcher-handle-indeterminate-bg);
      border-color: var(--base-switcher-handle-indeterminate-border-color, $base-switcher-handle-indeterminate-border-color);
    }

    .base-switcher--two-points.indeterminate .base-switcher__icon:focus & {
      box-shadow: $focus-shadow-options var(--base-switcher-handle-indeterminate-focus-shadow-color, $base-switcher-handle-indeterminate-focus-shadow-color);
    }

    .base-switcher--two-points.checked & {
      background-color: var(--base-switcher-handle-checked-bg, $base-switcher-handle-checked-bg);
      border-color: var(--base-switcher-handle-checked-border-color, $base-switcher-handle-checked-border-color);
      left: calc(100% - 14px);
    }

    .base-switcher--two-points.checked .base-switcher__icon:focus & {
      box-shadow: $focus-shadow-options var(--base-switcher-handle-checked-focus-shadow-color, $base-switcher-handle-checked-focus-shadow-color);
    }

    .base-switcher--two-points.checked-2 & {
      background-color: var(--base-switcher-handle-checked-2-bg, $base-switcher-handle-checked-2-bg);
      border-color: var(--base-switcher-handle-checked-2-border-color, $base-switcher-handle-checked-2-border-color);
      left: 0;
    }

    .base-switcher--two-points.checked-2 .base-switcher__icon:focus & {
      box-shadow: $focus-shadow-options var(--base-switcher-handle-checked-2-focus-shadow-color, $base-switcher-handle-checked-2-focus-shadow-color);
    }
  }

  &__label {
    font-size: 14px;
    line-height: 18px;
    margin-left: 7px;
    min-width: 0;
    flex: 1 0;
    align-self: center;

    .base-switcher--right & {
      margin-left: 0;
    }
  }
}
</style>
