export const themes = [
  {
    key: 'main',
    name: 'Main theme',
    logoSuffix: 'inverse',
    css: '/css/theme-main.css',
    favicon16: '/favicon-16x16.png',
    favicon32: '/favicon-32x32.png',
  },
  {
    key: 'light',
    name: 'Light theme',
    logoSuffix: 'norm',
    css: '/css/theme-light.css',
    favicon16: '/favicon-16x16.png',
    favicon32: '/favicon-32x32.png',
  },
  {
    key: 'olmero',
    name: 'Olmero theme',
    logoSuffix: 'inverse',
    css: '/css/theme-olmero.css',
    logo: {
      fileName: 'logo-olmero',
      alt: 'Olmero Smart',
      extension: '.png',
    },
    favicon16: '/img/favicons/favicon-olmero-16x16.png',
    favicon32: '/img/favicons/favicon-olmero-32x32.png',
    fonts: [
      'Open+Sans:wght@300;400;500',
      'Outfit:wght@300;400;700&display=swap',
    ],
    // fonts: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&family=Outfit:wght@300;400;700&display=swap',
  },
];
