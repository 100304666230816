<template>
  <component
    :is="viewComponent"
    ref="selectComponent"
    v-bind="viewComponentParams"
    class="select-geography"
    @select="onSelectItem"
    @autocomplete="onAutocomplete"
    @change-view-mode="onChangeViewMode"
    @change-prediction-value="$emit('change-prediction-value', $event)"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { getAdministrativeUnits, getSuggestGeo } from '@/api/repositories/geoRepository';
import { loadLocationList } from "@/shared/api-helpers/geoApiHelper";
import { prepareAdministrativeUnits } from "@/shared/administrative-units";

const SelectGeographyPlainView = () => import('@/components/forms/select-geography/SelectGeographyPlainView');
const SelectGeographySelectorView = () => import('@/components/forms/select-geography/SelectGeographySelectorView');

export default {
  name: 'select-geography',
  components: {
    SelectGeographyPlainView,
    SelectGeographySelectorView,
  },
  props: {
    idKey: { type: String, default: 'au_path' },
    nameKey: { type: String, default: 'title' },
    value: { type: [Array, Object], default: Array },
    listTypeId: { type: Number, default: null },
    locations: { type: Array, default: Array },
    disabled: { type: Boolean, default: false },
    plainView: { type: Boolean, default: false },
    showOtherCountriesCheckbox: { type: Boolean, default: false },
    isLoadingTotal: { type: Boolean, default: false },
    total: { type: Number, default: null },
    showListView: { type: Boolean, default: true },
    accessOptions: { type: Object, default: Object },
  },
  data() {
    return {
      viewComponent: 'select-geography-selector-view',
      isLoadingSearch: false,
      suggestLocations: [],
      loadedLocations: [],
    };
  },
  computed: {
    viewComponentParams() {
      const params = {
        idKey: this.idKey,
        nameKey: this.nameKey,
        value: this.value,
        isLoadingSearch: this.isLoadingSearch,
        suggestLocations: this.suggestLocations,
        locations: this.locationList,
        showOtherCountriesCheckbox: this.showOtherCountriesCheckbox,
        isLoadingTotal: this.isLoadingTotal,
        total: this.total,
        disabled: this.disabled,
      };

      if (!this.plainView) {
        Object.assign(params, {
          showListView: this.showListView,
        });
      }

      return params;
    },
    locationList() {
      return this.locations.length > 0 ? this.locations : this.loadedLocations;
    },
  },
  created() {
    if (this.plainView) {
      this.viewComponent = 'select-geography-plain-view';
    }
  },
  methods: {
    async onSelectItem(item) {
      let res;

      if (this.showOtherCountriesCheckbox) {
        const au_path = await this.getSelectedItem(item, this.value.au_path);
        res = { au_path };
      } else {
        res = await this.getSelectedItem(item, this.value);
      }

      this.$emit('input', res);
    },
    onChangeViewMode(viewMode) {
      if (viewMode === 'list' && this.locationList.length === 0) {
        this.loadLocationList();
      }
    },
    async onAutocomplete(searchText) {
      this.suggestLocations = await this.getSuggestGeo(searchText);
    },
    async loadLocationList() {
      this.isLoadingSearch = true;

      try {
        this.loadedLocations = await loadLocationList(this.accessOptions);
      } catch {}

      this.isLoadingSearch = false;
    },
    async getSelectedItem(item, value = []) {
      const unitTypes = [enums.ADMINISTRATIVE_UNIT_TYPES.DISTRICT];
      let res = value || [];

      if (item.administrative_unit_type_id === enums.ADMINISTRATIVE_UNIT_TYPES.DISTRICT) {
        res = _.union(res, [item[this.idKey]]);
      } else {
        const query = {
          limit: process.env.VUE_APP_MAX_LIMIT,
          offset: 0,
          filter: {
            administrative_unit_type_id: { in: unitTypes },
            administrative_units: { in: [item.id] },
          },
          fields: ['id', 'administrative_units'],
        };

        if (this.listTypeId === enums.LIST_TYPES.ALL) {
          query.access_type = 'all';
        }

        this.isLoadingSearch = true;

        //
        try {
          const response = await getAdministrativeUnits(query);
          const adUnits = response.data;
          const selectedIds = adUnits.map(el => el[this.idKey]);

          res = _.union(res, selectedIds);
        } catch (error) {
          console.log(error);
        }

        this.isLoadingSearch = false;
      }

      if (this.$refs.selectComponent) {
        this.$refs.selectComponent.clearSearchText();
      }

      return new Promise((resolve) => {
        resolve(res);
      });
    },
    async getSuggestGeo(searchText) {
      const res = [];
      const query = {
        search: searchText,
      };

      if (this.accessOptions.accessType) {
        query.access_type = this.accessOptions.accessType;
      }

      this.isLoadingSearch = true;

      try {
        const response = await getSuggestGeo(this.listTypeId, query);
        const sgItems = prepareAdministrativeUnits(response?.data || []);

        // if (!sgItems.length) {
        //   this.isLoadingSearch = false;
        //   return new Promise(resolve => resolve(res));
        // }

        const countries = sgItems.reduce((acum, item) => _.union(acum, [item.administrative_units[0]]), []);
        const countriesAdUnits = await getAdministrativeUnits({
          limit: countries.length,
          fields: ['id', 'country_id'],
          filter: {
            id: {
              in: countries,
            },
          },
          access_type: this.listTypeId === enums.LIST_TYPES.ALL ? 'all' : 'profile_countries',
        });
        const countriesMap = (countriesAdUnits?.data || []).reduce((acum, item) => ({ ...acum, [item.id]: item.country_id }), {});
        const locations = sgItems.map(item => ({
          ...item,
          country_id: countriesMap[item.administrative_units[0]],
          name: item.path,
        }));
        const groupedLocations = _.groupBy(locations, 'administrative_unit_type_id');
        const sort = [
          enums.ADMINISTRATIVE_UNIT_TYPES.COUNTRY,
          enums.ADMINISTRATIVE_UNIT_TYPES.REGION,
          enums.ADMINISTRATIVE_UNIT_TYPES.DISTRICT,
        ];

        sort
          .filter(sortKey => groupedLocations[sortKey] && groupedLocations[sortKey].length > 0)
          .forEach(sortKey => {
            const groupItems = groupedLocations[sortKey];

            if ([enums.ADMINISTRATIVE_UNIT_TYPES.REGION, enums.ADMINISTRATIVE_UNIT_TYPES.DISTRICT].includes(sortKey)) {
              const countryGroups = _.groupBy(groupItems, 'country_id');
              let regionName = '';

              if (sortKey === enums.ADMINISTRATIVE_UNIT_TYPES.REGION) {
                regionName = this.$vDict('global.text_region.text');
              } else if (sortKey === enums.ADMINISTRATIVE_UNIT_TYPES.DISTRICT) {
                regionName = this.$vDict('global.text_district.text');
              }

              Object.keys(countryGroups).forEach(countryId => {
                const country = this.$lFind('global.countries', { id: Number(countryId) });

                if (country && regionName) {
                  const groupName = country ? `${country.code}:${regionName}` : '';

                  res.push({
                    key: sortKey,
                    name: groupName,
                    items: countryGroups[countryId],
                  });
                }
              });
            } else {
              res.push({
                key: sortKey,
                name: '',
                items: groupItems,
              });
            }
          });
      } catch (error) {}

      this.isLoadingSearch = false;

      return new Promise(resolve => resolve(res));
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
