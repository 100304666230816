export default {
  project_external_ids: {
    enumKey: 'PROJECT_EXTERNAL_IDS',
    enumKeys: [
      {
        key: "ISTAV_ID",
        rsmCode: "istav_id",
      },
    ],
  },
};
