import Vue from 'vue';
import { SOURCE_TYPES } from "@/config/enums";

export function getSourceTypes() {
  const sourceTypes = Vue.prototype.$getEnumsList('SOURCE_TYPES');
  const sourceTypeIds = [
    SOURCE_TYPES.MYRAN_SMART,
    SOURCE_TYPES.SHARP,
  ];

  return sourceTypes.filter(sourceType => sourceTypeIds.includes(sourceType.id));
}

export function getDomainsOptions(domains = []) {
  return domains.map(domain => {
    const country = Vue.prototype.$lFind('global.countries', { id: domain.country_id });
    let name = domain.domain || '';

    if (country) {
      name += ` (${country.name})`;
    }

    return {
      id: domain.id,
      name,
    };
  });
}
