<template>
  <div class="searchable-list">
    <div class="searchable-list__header">
      <base-input
        :value="value"
        :placeholder="placeholder"
        @input="onInputSearch"
      >
        <template v-slot:input-prepend>
          <a
            v-show="value"
            data-cy="link-clear-search"
            class="search-keywords-clear"
            href="javascript:"
            @click="onInputSearch('')"
          >
            <svg-icon
              name="close"
              size="12"
            />
          </a>
          <svg-icon
            v-show="!value"
            name="keyword"
            size="16"
          />
        </template>
      </base-input>

      <slot name="input-after" />
    </div>
    <div
      v-if="isShowResult"
      class="searchable-list__body"
    >
      <loading v-if="isLoading" />
      <template v-else>
        <div
          v-if="listItems.length > 0"
          class="searchable-list__list"
        >
          <slot
            name="list"
            :list-props="listProps"
            :check-value="onCheckedValue"
          >
            <checked-list
              v-bind="listProps"
              @input="onCheckedValue"
            />
          </slot>
        </div>
        <div
          v-else
          class="searchable-list__not-found"
        >{{ $vDict('global.text_request_not_found.text') }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/forms/BaseInput';
import CheckedList from "@/components/forms/checked-lists/CheckedList";
import { getSearchRegex } from "@/shared/utils";

export default {
  name: 'searchable-list',
  components: {
    BaseInput,
    CheckedList,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    isMultiple: { type: Boolean, default: false },
    isStaticItems: { type: Boolean, default: false },
    minLetters: { type: Number, default: 2 },
    items: { type: Array, default: Array },
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    searchFunc: { type: Function, default: null },
    checked: { type: [Array, Number], default: null },
    searchKeys: { type: Array, default: () => ['name'] },
    formatter: { type: Function, default: null },
    nameKey: { type: String, default: 'name' },
    showResultAlways: { type: Boolean, default: false },
  },
  data() {
    return {
      showResults: false,
      listItems: [],
    };
  },
  computed: {
    listProps() {
      return {
        items: this.listItems,
        checked: this.checked,
        searchText: this.value,
        isMultiple: this.isMultiple,
        formatter: this.formatter,
        nameKey: this.nameKey,
      };
    },
    itemsStr() {
      return JSON.stringify(this.items);
    },
    hasMinLetters() {
      return this.value.length >= this.minLetters;
    },
    isShowResult() {
      return this.showResultAlways ||
        this.showResults ||
        this.isLoading ||
        this.isStaticItems;
    },
  },
  watch: {
    value(text = '') {
      if (text.length < this.minLetters) {
        this.showResults = false;
      }
    },
    itemsStr: {
      handler() {
        if (!this.isStaticItems) {
          this.listItems = _.cloneDeep(this.items);
        } else {
          this.searchItems();
        }

        this.listItems.sort((a, b) => {
          if (a.firstInList || b.firstInList) {
            return 1;
          }

          const checked = typeof this.checked === 'number' ?
            [this.checked] : this.checked || [];
          const includesA = checked.includes(a.id);
          const includesB = checked.includes(b.id);

          if (includesA && includesB) {
            return 0;
          } else if (includesA) {
            return -1;
          } else if (includesB) {
            return 1;
          }
        });
      },
      immediate: true,
    },
  },
  created() {
    this._searchFunc = this.isStaticItems ?
      this.searchItems : _.debounce(this.searchItems, 1000);
  },
  mounted() {
    this.showResults = this.hasMinLetters;
  },
  methods: {
    async searchItems() {
      if (this.isStaticItems) {
        this.listItems = this.getFilteredItems();
        return;
      }

      if (this.hasMinLetters || this.showResultAlways) {
        this.showResults = true;
        this.$emit('search', this.value);
      }
    },
    getFilteredItems() {
      let res = _.cloneDeep(this.items);

      if (this.hasMinLetters) {
        res = this.items.filter(item => {
          const values = this.searchKeys.map(key => item[key]);
          const text = _.compact(values).map(el => el.toLowerCase()).join(' ');
          return getSearchRegex(this.value).test(text);
        });
      }

      return res;
    },
    onInputSearch(text) {
      const isRunSearch = this.isStaticItems || this.showResultAlways;

      if (isRunSearch || text.length >= this.minLetters) {
        this._searchFunc();
      }

      this.$emit('input', text);
    },
    onCheckedValue(value) {
      this.$emit('checked-value', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.searchable-list {
  &__header {
    padding: 5px 3px 10px;
  }

  &__body {
    padding-top: 8px;
    border-top: 1px solid var(--line-color-main);
  }

  &__not-found {
    padding: 5px 10px;
  }

  &__list {
    overflow: auto;
    max-height: 240px;
    padding-right: 4px;
  }
}
</style>
