<template>
  <div
    ref="container"
    :class="{ 'copied-text--always-show-copy': alwaysShowCopy }"
    class="copied-text"
  >
    <div class="copied-text__text">
      <slot
        name="text"
        :text="text"
      >{{ text }}</slot>
    </div>
    <div class="copied-text__icon">
      <b-link
        v-b-tooltip.hover="{ boundary: 'window' }"
        :title="$vDict('global.text_copy_to_clipboard.text')"
        class="copied-text__icon__link"
        @click="copyText"
      >
        <svg-icon
          name="copy"
        />
      </b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "copied-text",
  props: {
    text: { type: String, default: '' },
    copiableText: { type: String, default: '' },
    alwaysShowCopy: { type: Boolean, default: false },
  },
  computed: {
    textFoCopy() {
      return this.copiableText || this.text;
    },
  },
  methods: {
    copyText() {
      this.$copyText(this.textFoCopy, this.$refs.container).then(() => {
        this.$notifToastr('success', this.$vDict('global.text_copy_to_clipboard_success.text'));
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.copied-text {
  display: flex;

  &--always-show-copy &__icon {
    opacity: 1;
  }

  &:hover &__icon {
    opacity: 1;
  }

  &__text {
    word-break: break-all;

    &::v-deep a {
      display: block;
    }
  }

  &__icon {
    opacity: 0;
    transition: opacity .5s;
    margin-left: 12px;

    &__link {
      color: var(--secondary);
    }

    .svg-icon {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
