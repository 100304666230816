import { COUNTRIES, ENV_MODES } from '@/config/enums';

const DEFAULT_COUNTRY_ID = COUNTRIES.SWEDEN;
const CURRENT_DOMAIN = window.location.hostname || '';

export const DOMAINS = {
  [ENV_MODES.LOCALHOST]: [
    { name: 'localhost', country_id: DEFAULT_COUNTRY_ID },
  ],
  [ENV_MODES.STAGE]: [
    { name: 'smart-dev.byggfakta.se', country_id: COUNTRIES.SWEDEN },
    { name: 'smart-dev.byggfakta.no', country_id: COUNTRIES.NORWAY },
    { name: 'smart-dev.byggefakta.dk', country_id: COUNTRIES.DENMARK },
    { name: 'smart-dev.rpt.fi', country_id: COUNTRIES.FINLAND },
    { name: 'smart-dev.istav.sk', country_id: COUNTRIES.SLOVAKIA },
    { name: 'smart-dev.istav.cz', country_id: COUNTRIES.CZECH },
  ],
  [ENV_MODES.PRODUCTION]: [
    { name: 'smart.byggfakta.se', country_id: COUNTRIES.SWEDEN },
    { name: 'smart.byggfakta.no', country_id: COUNTRIES.NORWAY },
    { name: 'smart.byggefakta.dk', country_id: COUNTRIES.DENMARK },
    { name: 'smart.rpt.fi', country_id: COUNTRIES.FINLAND },
    { name: 'smart.istav.sk', country_id: COUNTRIES.SLOVAKIA },
    { name: 'smart.istav.cz', country_id: COUNTRIES.CZECH },
  ]
}

export function getEnvMode() {
  return CURRENT_DOMAIN.includes('localhost') ? ENV_MODES.LOCALHOST :
    CURRENT_DOMAIN.includes('dev') ? ENV_MODES.STAGE :
      ENV_MODES.PRODUCTION;
}

export function getDomainCountryId() {
  const envMode = getEnvMode();
  const envDomains = DOMAINS[envMode] || [];
  const currentDomain = envDomains.find(el => el.name === CURRENT_DOMAIN);

  return currentDomain?.country_id || DEFAULT_COUNTRY_ID;
}
