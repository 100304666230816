<template>
  <div class="select-dropdown-search">
    <b-dropdown
      ref="list"
      data-cy="dropdown"
      :disabled="isLoading || disabled"
      :class="{ 'loading': isLoading }"
      :right="dropRight"
      variant="outline"
      class="select-dropdown-search__dropdown b-dropdown--select"
      lazy
      @shown="onDropdownShown"
      @hidden="onDropdownHidden"
    >
      <template slot="button-content">
        <v-spinner
          class="button-loader"
          name="circle"
          size="16"
        />

        <slot
          name="button-content"
          :button-text="buttonText"
          :selected-item="selectedItem"
        >
          <div
            class="dropdown-toggle__text"
            :title="selectedItemName"
          >
            {{ buttonText }}
          </div>
        </slot>
      </template>
      <b-dropdown-text
        tag="div"
        class="select-dropdown-search__content"
      >
        <searchable-list
          v-model="searchText"
          :items="formattedItems"
          :is-loading="searchLoading"
          :placeholder="inputPlaceholder"
          :checked="value"
          :is-static-items="isStaticItems"
          :options="options"
          :search-keys="searchKeys"
          :show-result-always="showResultAlways"
          :min-letters="minLetters"
          @search="onSearch"
          @checked-value="onSelectedValue"
        >
          <template v-slot:list="listProps">
            <slot
              name="search-list"
              v-bind="listProps"
            />
          </template>
        </searchable-list>
      </b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<script>
import SearchableList from "@/components/forms/SearchableList";

export default {
  name: 'select-dropdown-search',
  components: {
    SearchableList,
  },
  props: {
    isStaticItems: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    inputPlaceholder: { type: String, default: '' },
    options: { type: Array, default: Array },
    value: { type: Number, default: null },
    searchFunc: { type: Function, default: null },
    searchKeys: { type: Array, default: () => ['name'] },
    dropRight: { type: Boolean, default: false },
    showResultAlways: { type: Boolean, default: false },
    minLetters: { type: Number, default: 2 },
    selectedOption: { type: Object, default: null },
  },
  data() {
    return {
      selectedItem: null,
      searchText: '',
      isLoading: false,
      searchLoading: false,
      resultItems: [],
    };
  },
  computed: {
    items() {
      return this.isStaticItems ? this.options : this.resultItems;
    },
    formattedItems() {
      return this.items.filter(item => item.id !== this.value);
    },
    buttonText() {
      const placeholder = this.placeholder || this.$vDict('global.text_assigned_to.text');
      return this.selectedItem ? this.selectedItem.name : placeholder;
    },
    selectedItemStr() {
      return JSON.stringify(this.selectedItem);
    },
    selectedItemName() {
      return this.selectedItem?.name || '';
    },
  },
  watch: {
    value: {
      handler(itemId) {
        if (this.selectedItem?.id === itemId) {
          return;
        }

        this.initValues();
      },
      immediate: true,
    },
    selectedItemStr() {
      this.$emit('change-selected-item', this.selectedItem);
    },
  },
  methods: {
    initValues() {
      if (!this.value) {
        this.selectedItem = null;
        return;
      }

      const selectedItem = this.items.find(el => el.id === this.value);

      if (this.isStaticItems || selectedItem) {
        this.selectedItem = selectedItem;
        return;
      }

      if (this.selectedOption?.id === this.value) {
        this.selectedItem = this.selectedOption;
      } else {
        this.loadSelectedItem();
      }
    },
    async loadSelectedItem() {
      this.isLoading = true;

      try {
        const response = await this.searchFunc({
          filter: {
            id: {
              in: [this.value],
            },
          },
        });
        const items = response?.data || [];

        if (items[0]) {
          this.selectedItem = items[0];
        }
      } catch (error) {}

      this.isLoading = false;
    },
    async onSearch() {
      this.searchLoading = true;

      try {
        const query = {
          offset: 0,
          limit: 20,
        };

        if (this.searchText) {
          query.search = this.searchText;
        }

        const response = await this.searchFunc(query);

        this.resultItems = response?.data || [];
      } catch (error) {}

      this.searchLoading = false;
    },
    onSelectedValue(itemId) {
      this.$refs.list.hide(true);
      this.$emit('input', itemId);
    },
    onDropdownShown() {
      if (!this.showResultAlways) {
        return;
      }

      this.onSearch();
    },
    onDropdownHidden() {
      this.searchText = '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .select-dropdown-search {
    &.is-invalid::v-deep .dropdown-toggle {
      border-color: var(--input-border-error-color, $input-border-error-color);
    }

    &__dropdown {
      width: 100%;
    }

    &::v-deep {
      .dropdown-menu {
        width: 350px;
        min-width: 100%;
        max-width: calc(100vw - 20px);
      }

      .b-dropdown-text {
        padding: 5px;
      }

      .dropdown-toggle {
        &__icon {
          margin-top: -4px;
          margin-bottom: -4px;
        }
      }

      .searchable-list__list {
        overflow: auto;
        max-height: 240px;
        padding-right: 4px;
      }

      .c-avatar {
        --size: 24px;
      }
    }
  }
</style>
