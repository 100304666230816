<template>
  <div
    v-if="actions.length > 0"
    class="actions-field"
  >
    <v-spinner
      v-if="isLoading"
      class="action-loading"
    />
    <base-dropdown
      v-if="showAsDropdown"
      v-show="!isLoading"
      :dropleft="!horizontalDots"
      :right="horizontalDots"
      :items="actionsList"
      popupClass="entity-table-dropdown-actions-popup"
      append-to-body
      like-menu
      no-caret
      data-cy="dropdown-actions"
      class="dropdown-actions"
      @click-item="onAction"
    >
      <template v-slot:button-content>
        <div
          class="dropdown-dots"
          :class="{ 'dropdown-dots--vertical': !horizontalDots }"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </template>

      <template #link-content="{ item }">
        <div class="dropdown-item__icon">
          <svg-icon
            size="18"
            :name="item.icon"
          />
        </div>
        <div class="dropdown-item__text">{{ item.name }}</div>
      </template>
    </base-dropdown>
    <ul
      v-else
      v-show="!isLoading"
      class="list-item-actions"
    >
      <li
        v-for="(action, actionIndex) in actionsList"
        :key="actionIndex"
        class="list-item-actions__item"
      >
        <b-link
          v-bind="action.linkAttrs"
          :class="[action.linkClass, 'action-link--icon-link']"
          :disabled="action.disabled"
          @click="onAction(action)"
        >
          <svg-icon :name="action.icon" />
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import BaseDropdown from "@/components/forms/BaseDropdown";
import MixinCheckMobile from "@/mixins/MixinCheckMobile.vue";

export default {
  name: 'actions-field',
  props: {
    horizontalDots: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    actions: { type: Array, default: Array },
    possibleActions: { type: Array, default: Array },
  },
  mixins: [
    MixinCheckMobile,
  ],
  components: {
    BaseDropdown,
  },
  computed: {
    showAsDropdown() {
      return this.possibleActions.length > 2 ||
        (this.isMobile && this.actions.length > 0);
    },
    actionsList() {
      return this.actions.map(action => ({
        ...action,
        linkClass: _.compact([
          'action-link',
          action.linkClass,
        ]).join(' '),
      }));
    },
  },
  methods: {
    onAction(action) {
      this.$emit('action', action);
    },
  },
};
</script>

<style lang="scss" scoped>
.actions-field {
  display: inline-block;
}

.b-dropdown::v-deep {
  .dropdown-dots span {
    border-color: var(--dropdown-link-dots-color);
  }

  .dropdown-toggle:hover .dropdown-dots span,
  &.show .dropdown-toggle .dropdown-dots span {
    border-color: var(--dropdown-link-dots-active-color);
    background-color: var(--dropdown-link-dots-active-color);
  }
}

.action-loading {
  width: 22px;
  height: 22px;
  margin-left: auto;
}

.list-item-actions {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;

  &__item {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    .svg-icon {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}
</style>

<style lang="scss">
.entity-table-dropdown-actions-popup,
.entity-table  {
  .action-link {
    &--icon-link {
      color: var(--secondary);
      padding: 3px;
      display: block;

      &:hover {
        color: var(--link-secondary-hover-color);
      }
    }

    &--warning {
      color: var(--icon-del-color);

      &:hover {
        color: var(--icon-del-hover-color);
      }
    }

    &--secondary, &--secondary:hover {
      color: var(--secondary);
    }

    &.disabled, &.disabled:hover {
      color: var(--text-muted);
    }
  }
}
</style>
