<template>
  <transition
    name="transition-expand"
    @enter="vEnter"
    @after-enter="vAfterEnter"
    @leave="vLeave"
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'transition-expand',
  data() {
    return {
      height: 0,
    };
  },
  methods: {
    vEnter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.maxHeight = 'none';

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.maxHeight = 0;

      setTimeout(() => {
        element.style.maxHeight = height;
      });
    },
    vAfterEnter(element) {
      element.style.maxHeight = null;
      this.$emit('after-enter', element);
    },
    vLeave(element) {
      const height = getComputedStyle(element).height;

      element.style.maxHeight = height;

      setTimeout(() => {
        element.style.maxHeight = 0;
      });
    },
  },
};
</script>
