import {
  COUNTRIES,
  CLIENT_COMPANY_FOLDER_TYPES,
  CLIENT_ROLE_TYPES,
  US_DATE_RANGE_TYPES,
  LANGUAGES,
} from '@/config/enums';

export default {
  // DateTime
  FORMAT_SHORT_DATE: 'DD MMM YYYY',
  FORMAT_SHORT_DATE_TIME: 'DD MMM YYYY HH:mm',
  FORMAT_SHORT_MONTH_YEAR: 'MMM YYYY',
  FORMAT_DATE_ACTIVITY_START: 'dddd [<b>]DD MMM[</b>] [at] HH:mm',
  FORMAT_DATE_ACTIVITY_EVENT: 'YYYY-MM-DD HH:mm',
  FORMAT_DATE_NOTE_MODIFIED: 'dddd [<b>]DD MMM[</b>] [at] HH:mm',
  FORMAT_MONTH_YEAR: 'MMMM YYYY',

  // Luxon DateTime
  LUXON_API_DATE: 'yyyy-LL-dd',
  LUXON_API_DATE_TIME: 'yyyy-LL-dd HH:mm',
  LUXON_FORMAT_SHORT_DATE: 'dd LLL yyyy',
  LUXON_FORMAT_SHORT_DATE_TIME: 'dd LLL yyyy HH:mm',
  LUXON_FORMAT_SHORT_MONTH_YEAR: 'LLL yyyy',
  LUXON_FORMAT_DATE_ACTIVITY_START: 'cccc \'<b>\'dd LLL yyyy\'</b>\' \'at\' HH:mm',
  LUXON_FORMAT_DATE_ACTIVITY_EVENT: 'yyyy-LL-dd HH:mm',
  LUXON_FORMAT_DATE_ACTIVITY_LONG: 'cccc \'<b>\'dd LLL\'</b>\' \'at\' HH:mm',
  LUXON_FORMAT_DATE_NOTE_MODIFIED: 'cccc \'<b>\'dd LLL yyyy\'</b>\' \'at\' HH:mm',
  LUXON_FORMAT_MONTH_YEAR: 'LLLL yyyy',

  // Array
  FICTITIOUS_COMPANY_TYPES: [15],

  // Input Masks
  INPUT_MASK_POSTCODE: {
    [COUNTRIES.SWEDEN]: {
      mask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/], // 100 00 (5 digits with space)
      defaultValue: '100 00',
      ranges: [[10000, 99999]],
    },
    [COUNTRIES.DENMARK]: {
      mask: [/\d/, /\d/, /\d/, /\d/], // (4 digits)
      defaultValue: '1000',
      ranges: [[1000, 9999]],
    },
    [COUNTRIES.FINLAND]: {
      mask: [/\d/, /\d/, /\d/, /\d/, /\d/], // (5 digits)
      defaultValue: '',
      ranges: [[0, 99999]],
    },
    [COUNTRIES.NORWAY]: {
      mask: [/\d/, /\d/, /\d/, /\d/], // (4 digits)
      defaultValue: '',
      ranges: [[0, 9999]],
    },
    [COUNTRIES.SLOVAKIA]: {
      mask: [/\d/, /\d/, /\d/, /\d/, /\d/], // (5 digits)
      defaultValue: '',
      ranges: [[0, 9999], [80000, 99999]],
    },
    [COUNTRIES.CZECH]: {
      mask: [/\d/, /\d/, /\d/, /\d/, /\d/], // (5 digits)
      defaultValue: '',
      ranges: [[10000, 79999]],
    },
    [COUNTRIES.SWITZERLAND]: {
      mask: [/\d/, /\d/, /\d/, /\d/], // (4 digits)
      defaultValue: '',
      ranges: [[0, 99999]],
    },
    [COUNTRIES.LIECHTENSTEIN]: {
      mask: [/\d/, /\d/, /\d/, /\d/], // (4 digits)
      defaultValue: '',
      ranges: [[0, 99999]],
    },
    OTHER: {
      mask: [/\d/, /\d/, /\d/, /\d/, /\d/], // (5 digits)
      defaultValue: '',
      ranges: [[0, 99999]],
    },
  },

  // Consts
  CONST_SYSTEM_TAGS: [
    {
      name: 'Clients',
      client_company_folder_type_id: CLIENT_COMPANY_FOLDER_TYPES.CLIENT,
    },
    {
      name: 'Competitors',
      client_company_folder_type_id: CLIENT_COMPANY_FOLDER_TYPES.COMPETITOR,
    },
  ],
  ROLE_TYPES: [
    {
      id: CLIENT_ROLE_TYPES.PARTNER_ROLE,
      key: 'partner_roles',
    },
    {
      id: CLIENT_ROLE_TYPES.PROJECT_TRIGGER_RESTRICTION_ROLE,
      key: 'project_trigger_restrictions',
    },
    {
      id: CLIENT_ROLE_TYPES.TENDER_TRIGGER_RESTRICTION_ROLE,
      key: 'tender_trigger_restrictions',
    },
  ],
  NOTIF_UPDATE_TIMES: [
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
  ],

  // DICT
  DICT_SYSTEM_INIT: {
    lookups: ['global', 'client'],
    vocabularies: [
      'enums',
      'navbar',
      'global',
      'general',
      'entity_list',
      'triggers',
      'search',
      'notes',
      'activities',
      'setup_assistant',
      'projects',
      'companies',
      'tenders',
      'contacts',
      'activities',
      'fields_company_related',
      'breadcrumb',
      'errors',
    ],
  },

  // defaults text
  TEXT_DEFAULT_UNEXPECTED_ERROR: 'Unexpected error. Try again later',

  // defaults
  DEFAULT_US_DATE_RANGE_TYPE: US_DATE_RANGE_TYPES.WEEKLY_DATA,
  DEFAULT_LANG_ID: LANGUAGES.ENGLISH,

  // Consts
  CONST_SYSTEM_START_DATE: '2019-01-01',
  CONST_MAX_BUDGET_LIMIT: 1000 * 1.0e+6,

  // LIMITS
  INPUT_LIMIT_MAX_SYMBOLS_DEFAULT: 40,

  // Events
  EVENT_OPTIONS_NO_CAPTURE: { passive: true, capture: false },
};
