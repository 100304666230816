<template>
  <div class="selections-field">
    <ul class="selections-list">
      <li
        class="selections-list__item"
        v-for="selection in selections"
      >
        <div class="selections-list__name">{{ selection.getName() }}</div>

        <b-link
          v-if="viewOnly"
          class="selections-list__view-link"
          @click.prevent="showSelectionInfoModal(selection)"
        >
          <svg-icon
            class="selections-list__link"
            name="card-info"
          />
        </b-link>

        <b-link
          v-else
          class="selections-list__edit-link"
          @click.prevent="showEditUserSelectionFormModal(selection)"
        >
          <svg-icon
            class="selections-list__link"
            name="popup"
          />
        </b-link>
      </li>
    </ul>

    <b-link
      v-if="showAdd"
      class="selection-add-link"
      @click="addSelection"
    >
      <svg-icon
        name="plus-circle"
      />
    </b-link>

    <smart-selection-info-modal
      ref="selectionInfoModal"
      :selection="selectedSelection"
    />
  </div>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import SmartSelectionInfoModal from "@/entities/smart-selection/views/SmartSelectionInfoModal.vue";

export default {
  name: 'selections-field',
  components: {
    BaseModal,
    SmartSelectionInfoModal,
  },
  props: {
    selections: { type: Array, default: Array },
    viewOnly: { type: Boolean, default: false },
    showAdd: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedSelection: null,
    }
  },
  methods: {
    showSelectionInfoModal(selection) {
      this.selectedSelection = selection;
      this.$refs.selectionInfoModal.show();
    },
    showEditUserSelectionFormModal() {

    },
    addSelection() {

    },
  }
}
</script>

<style lang="scss" scoped>
.selections-field {
  display: flex;
}

.selections-list {
  list-style: none;
  padding: 0;
  margin-right: auto;
  margin-bottom: 0;

  &__item {
    display: flex;
    margin-bottom: 8px;
  }

  &__name {
    margin-right: 4px;
  }

  &__edit-link {
    color: var(--secondary);

    .svg-icon {
      --size: 16px;
    }
  }

  &__view-link {
    color: var(--secondary);

    .svg-icon {
      --size: 12px;
    }
  }
}

.selection-add-link {
  margin-right: 4px;
  color: var(--secondary);

  .svg-icon {
    --size: 16px;
  }
}
</style>
