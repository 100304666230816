export default {
  name: 'table-view-mixin',
  props: {
    items: { type: Array, default: Array },
    fields: { type: Array, default: Array },
    sort: { type: Object, default: null },
    entityList: { type: Object, default: Object },
    tbodyTrClass: { type: Function, default: () => '' },
    fixedRequiredColumns: { type: Boolean, default: false },
  },
  data() {
    return {
      loadingEntityIds: [],
    };
  },
  computed: {
    dataset() {
      return this.entityList.dataset;
    },
    clientUsers() {
      return this.$store.getters['UserClient/users'];
    },
    possibleActions() {
      return this.entityList.selfClass.entityClass.getAllActions();
    },
  },
  methods: {
    addLoadingEntityId(entityId) {
      this.loadingEntityIds.push(entityId);
    },
    removeLoadingEntityId(entityId) {
      this.loadingEntityIds = this.loadingEntityIds.filter(el => el !== entityId);
    },
    isLoadingEntityId(entityId) {
      return this.loadingEntityIds.includes(entityId);
    },
    changeSort(sort = {}) {
      this.$emit('change-sort', sort);
    },
    async entityActionHandler(entity, action) {
      if (!this.$refs.entityActions) {
        return;
      }

      this.addLoadingEntityId(entity.id);

      try {
        await this.$refs.entityActions.actionHandler(entity, action);
      } catch (e) {
        this.$notifUnexpectedError();
      }

      this.removeLoadingEntityId(entity.id);
    },
  },
};
