import Vue from "vue";
import MixinUrlParams from "@/mixins/MixinUrlParams.vue";
import { parseIntFromString } from "@/shared/utils";

export const urlPageParams = Vue.extend({
  mixins: [
    MixinUrlParams,
  ],
  methods: {
    getUrlPage() {
      const pageParams = this.getUrlParam('page')?.split(',');

      if (!pageParams) return {};

      return pageParams.reduce((acc, param) => {
        const [listTypeId, page] = param.split(':');
        return {
          ...acc,
          [listTypeId]: Number(page),
        };
      }, {});
    },
    async setUrlPage(pageNumber) {
      const pageParams = this.getUrlPage();

      if (pageNumber > 1) {
        pageParams[this.entityList.listTypeId] = pageNumber;
      } else {
        delete pageParams[this.entityList.listTypeId];
      }

      const params = _.map(pageParams, (value, key) => `${key}:${value}`).join(',');
      await this.setUrlParam('page', params);
    },
    async checkUrlPage() {
      const params = this.getUrlPage();
      const pageNumber = parseIntFromString(params[this.entityList.listTypeId]);

      if (pageNumber) {
        this.entityList.currentPage = pageNumber;
      }
    },
  },
});
