<template>
  <div
    :class="classes"
    :aria-expandable="expandable"
    :aria-expanded="expanded"
    :style="elStyle"
    class="items-clamp"
  >
    <div
      ref="body"
      class="items-clamp__body"
      :style="bodyStyle"
    >
      <slot />
    </div>
    <div
      v-if="minItems > 0"
      class="items-clamp__trigger"
    >
      <button
        class="items-clamp__btn"
        @click="expandItems"
      >
        <svg-icon
          name="select-arrow"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { visibilityObserver } from '@/shared/utils';

export default {
  name: 'items-clamp',
  props: {
    lines: { type: Boolean, default: false },
    minItems: { type: Number, default: 3 },
    asyncData: { type: Array, default: Array },
  },
  data() {
    return {
      expandable: false,
      expanded: false,
      collapsing: false,
      height: 0,
      maxHeight: null,
      showAll: false,
      visibleObserver: null,
      isAnimationRunning: false,
    };
  },
  computed: {
    classes() {
      const isClampItems = !this.lines && this.expandable;

      return {
        'clamp-lines': this.lines,
        'clamp-items': isClampItems,
        'clamp-items--show-all': isClampItems && (this.expanded || this.collapsing || this.showAll),
        'items-clamp--expandable': this.expandable,
        'items-clamp--expanded': this.expanded,
        'items-clamp--collapsing': this.collapsing,
        'items-clamp--flatten': this.minItems === 0 || this.showAll,
        'items-clamp--animation': this.isAnimationRunning,
      };
    },
    elStyle() {
      let style = null;

      if (this.expandable && this.maxHeight !== null) {
        const compMaxHeight = this.expanded && !this.collapsing ? this.maxHeight : this.height;

        style = {
          maxHeight: `${compMaxHeight}px`,
        };
      }

      return style;
    },
    bodyStyle() {
      return this.minItems > 0 && this.lines ? {
        webkitLineClamp: this.minItems,
      } : null;
    },
  },
  watch: {
    asyncData: {
      handler() {
        if (this.minItems > 0) {
          setTimeout(() => {
            this.checkExpandable();
          }, 100);
        }
      },
    },
  },
  mounted() {
    if (this.minItems > 0) {
      const { body } = this.$refs;

      window.addEventListener('resize', this.onResize);

      if (body) {
        this.visibleObserver = visibilityObserver(body, (visible, obs) => {
          if (visible) {
            this.onResize();
            this.$nextTick(() => {
              obs.unobserve(body);
            });
          }
        });
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);

    if (this.visibleObserver) {
      this.visibleObserver.disconnect();
    }
  },
  methods: {
    initItemsClasses() {
      const elBody = this.$refs.body;
      const items = elBody ? elBody.querySelectorAll(`.clamp-item:nth-child(-n+${this.minItems})`) : [];

      if (items && items.length > 0) {
        const className = 'clamp-item--show';

        items.forEach((item, i) => {
          const arr = item.className.split(' ');
          if (arr.indexOf(className) === -1) {
            items[i].className += ` ${className}`;
          }
        });
      }
    },
    expandItems() {
      if (!this.expanded) {
        const height = this.$el.clientHeight;

        this.height = height;
        this.maxHeight = height;
        this.showAll = true;

        this.$nextTick(() => {
          this.maxHeight = this.$refs.body.scrollHeight;
          this.showAll = false;
          this.expandLines();
        });
      } else {
        this.maxHeight = this.height;
        this.expandLines();
      }
    },
    expandLines() {
      this.isAnimationRunning = true;

      if (!this.expanded) {
        this.expanded = true;
        this.$el.addEventListener(
          'transitionend',
          () => {
            this.isAnimationRunning = false;
          },
          { once: true },
        );
      } else {
        this.$el.addEventListener(
          'transitionend',
          () => {
            this.isAnimationRunning = false;
            this.expanded = false;
            this.collapsing = false;
          },
          {once: true},
        );
      }
    },
    checkExpandable() {
      const el = this.$el;
      const elBody = this.$refs.body;

      if (!elBody) {
        return;
      }

      if (this.lines) {
        const elHeight = el.clientHeight;
        const { scrollHeight } = elBody;

        if (elHeight < scrollHeight) {
          this.height = elHeight;
          this.maxHeight = scrollHeight;
          this.expandable = true;
        }
      } else if (this.minItems > 0) {
        const clampItems = elBody.querySelectorAll('.clamp-item');
        this.expandable = clampItems.length > this.minItems;

        if (this.expandable) {
          this.initItemsClasses();
        }
      }
    },
    onResize() {
      this.$el.style.maxHeight = 'none';
      this.expanded = false;
      this.height = 0;
      this.maxHeight = null;
      this.expandable = false;
      this.showAll = false;

      this.$nextTick(() => {
        this.checkExpandable();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.items-clamp {
  display: block;
  position: relative;
  padding-right: 35px;
  transition: 0.2s ease-in-out;
  transition-property: max-height;

  &.clamp-lines,
  &.clamp-lines &__body,
  &--animation,
  &--animation &__body {
    overflow: hidden;
  }

  &.clamp-items:not([class*="clamp-items--show-all"])::v-deep {
    .clamp-item:not([class*="clamp-item--show"]) {
      display: none;
    }
  }

  &.clamp-lines &__body {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  &--flatten.clamp-lines &__body {
    display: block;
    -webkit-line-clamp: none;
    text-overflow: clip;
    overflow: visible;
  }

  &--expanded.clamp-lines &__body {
    display: block;
    -webkit-line-clamp: none;
  }

  &__trigger {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &--expandable &__trigger {
    display: block;
  }

  &__btn {
    padding: 2px 0 0;
    margin: 0;
    border: 0;
    outline: 0;
    background: none;
    color: var(--secondary, $secondary);
    line-height: 0;
    width: 32px;
    height: 21px;
    opacity: .7;
    transition: 0.2s ease-in-out;
    transition-property: opacity, color;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 1;
    }

    .svg-icon {
      display: block;
      width: 10px;
      height: 10px;
      transition: 0.2s ease-in-out;
      transition-property: transform, color;
    }
  }

  &--expanded &__btn {
    .svg-icon {
      transform: rotate(-180deg);
    }
  }

  &--collapsing &__btn {
    .svg-icon {
      transform: rotate(0);
    }
  }
}
</style>
