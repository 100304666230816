<template>
  <div class="id-link-field">
    <div
      v-if="value"
      class="id-link-field__text"
    >{{ value }}</div>
    <div class="id-link-field__link">
      <b-link
        :to="routeTo"
        class="text-secondary"
      ><svg-icon name="popup" size="14" /></b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "id-link-field",
  props: {
    value: { type: [Number, String], default: null },
    routeTo: { type: Object, default: Object },
  },
}
</script>

<style lang="scss" scoped>
.id-link-field {
  display: flex;

  &__text {
    margin-right: 4px;
  }
}
</style>
