<template>
  <svg
    :class="className"
    :style="sizeStyles"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <use :xlink:href="`#${name}`" />
  </svg>
</template>

<script>
export default {
  name: 'svg-icon',
  props: {
    name: {
      type: [String, Boolean],
    },
    size: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
  computed: {
    // iconPath() {
    //   let url = '';
    //   try {
    //     let icon = require(`@/assets/icons/${this.name}.svg`);
    //     if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
    //       icon = icon.default;
    //     }
    //     url = icon.url;
    //   } catch (error) {
    //     console.log(`Error loading icon "${this.name}".`);
    //   }
    //   return url;
    // },
    className() {
      return `svg-icon svg-icon--${this.name}`;
    },
    sizeStyles() {
      const res = {};
      const width = parseInt(this.size) > 0 ? this.size :
        parseInt(this.width) > 0 ? this.width : null;
      const height = parseInt(this.size) > 0 ? this.size :
        parseInt(this.height) > 0 ? this.height : null;

      if (width) {
        res.width = `${width}px`;
      }
      if (height) {
        res.height = `${height}px`;
      }

      return res;
    },
  },
};
</script>

<style>
.svg-icon {
  --size: 24px;

  position: relative;
  fill: currentColor;
  height: var(--size);
  width: var(--size);
  display: inline-block;
}
</style>
