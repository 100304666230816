import { COUNTRIES } from "@/config/enums";

export const logoByCountry = {
  [COUNTRIES.SWEDEN]: {
    fileName: 'logo',
    alt: 'Byggfakta Smart',
  },
  [COUNTRIES.DENMARK]: {
    fileName: 'logo-dk',
    alt: 'Byggefakta Smart',
  },
  [COUNTRIES.FINLAND]: {
    fileName: 'logo-fi',
    alt: 'RPT Smart',
  },
  [COUNTRIES.NORWAY]: {
    fileName: 'logo',
    alt: 'Byggfakta Smart',
  },
  [COUNTRIES.CZECH]: {
    fileName: 'logo-istav',
    alt: 'Istav Smart',
  },
  [COUNTRIES.SLOVAKIA]: {
    fileName: 'logo-istav',
    alt: 'Istav Smart',
  },
};
