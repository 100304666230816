<template>
  <entity-table
    :striped="striped"
    :row-number="rowNumber"
    :items="items"
    :fields="fields"
    :sort="sort"
    :fixed-header="entityList.config.isMain"
    :dataset="entityList.dataset"
    :fixed-required-columns="fixedRequiredColumns"
    :actions="entityActions"
    :loading-items="entityList.loadingItems"
    :tbody-tr-class="tbodyTrClass"
    :current-page="entityList.currentPage"
    :per-page="entityList.perPage"
    :selectable="entityList.config.hasBulkOperations"
    :selected-items="entityList.selectedItems"
    @sort-changed="onChangeSort"
    @action="onEntityAction"
    @select="onSelectItems"
  >
    <!-- Headers -->
    <template
      v-for="(field, index) in fields"
      v-slot:[`head(${field.key})`]="data"
    >
      <slot
        v-bind="data"
        :name="`head(${field.key})`"
      >
        <span class="entity-table-head">
          <span class="entity-table-head__label">{{ data.label }}</span>
          <span
            v-if="field.sectionKey === 'custom_fields' && field.data.custom_field_type_id === CUSTOM_FIELD_TYPES.PERCENT"
            class="entity-table-head__measure"
          >%</span>
          <span
            v-else-if="field.sectionKey === 'custom_fields' && field.data.custom_field_type_id === CUSTOM_FIELD_TYPES.FORMULA"
            class="entity-table-head__measure"
          >
            <formula-info-link
              :formula="field.data.formula"
              :entity-type-id="field.data.entity_type_id"
            />
          </span>
          <span
            v-else-if="data.field.measure"
            class="entity-table-head__measure"
          >{{ data.field.measure }}</span>
        </span>
      </slot>
    </template>

    <template v-slot:row="data">
      <slot
        name="row"
        v-bind="data"
      />
    </template>

    <!-- Columns -->
    <template
      v-for="(field, index) in fields"
      v-slot:[`cell(${field.key})`]="data"
    >
      <field-custom
        v-if="field.sectionKey === 'custom_fields'"
        :field-data="field.data"
        :value="data.item.getCustomFieldValue(field.data.id)"
      />
      <slot
        v-else
        v-bind="data"
        :name="`cell(${field.key})`"
        :dataset="entityList.dataset"
      >
        <template v-if="!hasSlot(`cell(${field.key})`) && !field.details">{{ data.value }}</template>
      </slot>
    </template>

    <template v-slot:cell(actions)="data">
      <slot
        v-bind="data"
        name="cell(actions)"
      />
    </template>
  </entity-table>
</template>

<script>
import EntityTable from '@/components/table/EntityTable';
import MixinSlots from "@/mixins/MixinSlots";
import {
  CUSTOM_FIELD_TYPES,
} from '@/config/enums';

const BaseDropdown = () => import('@/components/forms/BaseDropdown');
const FieldCustom = () => import('@/components/entity-fields/FieldCustom');
const FormulaInfoLink = () => import("@/components/custom-fields/FormulaInfoLink");
const ColumnsInfo = () => import('@/components/ColumnsInfo');

export default {
  name: 'base-table-view',
  mixins: [
    MixinSlots,
  ],
  components: {
    EntityTable,
    FieldCustom,
    FormulaInfoLink,
    ColumnsInfo,
    BaseDropdown,
  },
  props: {
    striped: { type: Boolean, default: true },
    items: { type: Array, default: Array },
    fields: { type: Array, default: Array },
    sort: { type: Object, default: null },
    fixedRequiredColumns: { type: Boolean, default: false },
    entityList: { type: Object, default: Object },
    rowNumber: { type: Boolean, default: false },
    tbodyTrClass: { type: Function, default: () => '' },
  },
  data() {
    return {
      CUSTOM_FIELD_TYPES,
    };
  },
  computed: {
    itemsId() {
      return this.items.map(el => el.id);
    },
    entityActions() {
      return this.entityList.selfClass.entityClass.getAllActions();
    },
  },
  methods: {
    onChangeSort(sort) {
      this.$emit('change-sort', sort);
    },
    onEntityAction(action) {
      this.entityList.doEntityAction(action);
    },
    onSelectItems(ids = []) {
      this.entityList.selectedItems = ids;
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-table-head {
  &__label {
    margin-right: 4px;
  }
}
</style>
