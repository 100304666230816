<template>
  <div
    class="v-spinner"
    :style="sizeStyles"
    :class="className"
  >
    <div class="sk-circle">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'v-spinner',
  props: {
    size: { type: String, default: '' },
    width: { type: [Number, String], default: '' },
    height: { type: [Number, String], default: '' },
    className: { type: String, default: '' },
  },
  computed: {
    sizeStyles() {
      const res = {};
      const width = parseInt(this.size) > 0 ? this.size :
        parseInt(this.width) > 0 ? this.width : null;
      const height = parseInt(this.size) > 0 ? this.size :
        parseInt(this.height) > 0 ? this.height : null;

      if (width) {
        res.width = `${width}px`;
      }
      if (height) {
        res.height = `${height}px`;
      }

      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~spinkit/scss/spinkit.scss";

.v-spinner {
  --size: 24px;

  height: var(--size);
  width: var(--size);
}

.sk-circle {
  width: 100%;
  height: 100%;
  margin: 0 auto !important;
}

.sk-circle .sk-child::before {
  background-color: currentColor;
}
</style>
