export const queryFields = [
  'filter',
  'fields',
  'limit',
  'offset',
  'sort',
  'aggs',
  'search',
];
export const queryFilterTypes = [
  'gte',
  'lte',
  'in',
  'not_in',
  'eq',
  'all',
  'q',
];
export const filterRangeTypes = ['gte', 'lte'];
export const filterArrayTypes = ['in', 'not_in'];

export default {
  queryFields,
  queryFilterTypes,
  filterRangeTypes,
};
