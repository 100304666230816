import store from '@/store';

export const homeRoutes = [
  {
    route: {
      name: 'MyProjects',
    },
    component: 'user_projects',
    breadcrumbKey: 'my_projects',
  },
  {
    route: {
      name: 'MyTenders',
    },
    component: 'user_tenders',
    breadcrumbKey: 'my_tenders',
  },
  {
    route: {
      name: 'MyCompanies',
    },
    component: 'user_companies',
    breadcrumbKey: 'my_companies',
  },
  {
    route: {
      name: 'MyContacts',
    },
    component: 'user_contacts',
    breadcrumbKey: 'my_contacts',
  },
];

export function getRequiredRoute(toName = null) {
  const userIsDummy = store.getters['Account/getSettingsValue']('user.is_dummy');

  if (userIsDummy) {
    return {
      name: 'Invite',
    };
  }

  return null;
}

export function getDashboardRoute() {
  if (store.getters['Account/hasComponent']('dshbrd')) {
    return {
      name: 'Dashboard',
    };
  }

  return null;
}

export function getHomeRoute() {
  if (!store.getters['Account/isAuthorized']()) {
    return { name: 'Login' };
  }

  const userComponents = store.getters['Account/getSettingsValue']('user.components') || [];
  const homeRoute = homeRoutes.find(el => userComponents.includes(el.component));
  const requiredRouteName = getRequiredRoute();
  const dashboardRouteName = getDashboardRoute();
  let res;

  if (dashboardRouteName) {
    res = dashboardRouteName;
  } else if (requiredRouteName) {
    res = requiredRouteName;
  } else {
    res = homeRoute?.route || {
      name: 'Home',
    };
  }

  return res;
}

export function goNextRoute(from, nextRoute) {
  if (nextRoute && (from.name === nextRoute.name || from.path === nextRoute.path)) {
    setTimeout(() => {
      store.commit('DLH_SUCCESS', {
        type: 'middleware',
      });
    }, 1000);
  }

  return nextRoute || null;
}
