<template>
  <div
    :class="{ 'view-only': viewOnly }"
    class="checkboxes-columns"
    data-cy="checkbox-group"
  >
    <b-form-checkbox
      v-for="item in checkboxItems"
      :key="item.id"
      :value="item.id"
      :checked="value"
      class="checkboxes-columns__item custom-control--paddings"
      v-bind="getCheckboxProps(item)"
      @change="updateChecked"
    >
      <div class="custom-control-label__body">
        <div class="checkbox-content">
          <div class="custom-control-label__name">{{ item.name }}</div>
          <div
            v-if="item.countries"
            class="custom-control-label__badges"
          >
            <b-badge
              v-for="country in $lGetItemCountries(item)"
              :key="country"
              class="country-badge"
              variant="info"
            >{{ country }}</b-badge>
          </div>
        </div>
        <div
          v-if="viewOnly"
          class="checkbox-checked"
        >
          <svg-icon name="check-mark" />
        </div>
      </div>
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: 'checkboxes-column',
  props: {
    items: { type: Array, default: Array },
    value: { type: Array, default: Array },
    disabled: { type: Boolean, default: false },
    viewOnly: { type: Boolean, default: false },
    showAsChecked: { type: Array, default: Array },
  },
  computed: {
    checkboxItems() {
      if (!this.viewOnly) {
        return this.items;
      }

      const items = _.cloneDeep(this.items);
      return items.sort((a, b) => {
        const checkedA = this.value.includes(a.id);
        const checkedB = this.value.includes(b.id);

        if (!checkedA && checkedB) {
          return 1;
        }
        if (checkedA && !checkedB) {
          return -1;
        }
        return 0;
      });
    },
  },
  methods: {
    getCheckboxProps(item) {
      const isShowAsChecked = this.showAsChecked.includes(item.id);

      return {
        disabled: this.disabled || item.disabled || isShowAsChecked,
        class: {
          'custom-checkbox--show-as-checked': isShowAsChecked,
        },
      };
    },
    updateChecked(checked) {
      this.$emit('input', checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkboxes-columns {
  @include media-breakpoint-up(sm) {
    columns: 2;
    column-gap: 16px;
  }

  &.view-only::v-deep {
    .custom-control-label {
      color: var(--text-shaded);
      pointer-events: none;

      &::before, &::after {
        display: none;
      }
    }
  }

  &.view-only {
    &--show-as-checked::v-deep .custom-control-label,
    &::v-deep .custom-control-input:checked ~ .custom-control-label {
      color: var(--color-text-main);

      .checkbox-checked {
        display: block;
        align-self: flex-start;
      }

      .badge {
        font-size: 12px;
        line-height: 14px;
        min-width: 14px;
        height: 14px;
        padding: 0 4px;
      }
    }
  }

  &__item {
    break-inside: avoid;
  }
}

.checkbox-content {
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  &__name {
    flex: 1;
  }

  &__badges {
    flex-shrink: 0;
  }
}

.checkbox-checked {
  display: none;
  flex-shrink: 0;

  .svg-icon {
    --size: 12px;
  }
}
</style>
