<template>
  <div
    class="entity-status-tag"
    :class="variantClass"
  >
    <div
      v-if="icon"
      class="entity-status-tag__icon"
    >
      <svg-icon
        :name="icon"
      />
    </div>
    <div class="entity-status-tag__text">{{ name }}</div>
  </div>
</template>

<script>
export default {
  name: 'entity-status-tag',
  props: {
    variant: { type: String, default: '' },
    name: { type: String, default: '' },
    icon: { type: String, default: '' },
  },
  computed: {
    variantClass() {
      return `entity-status-tag--${this.variant}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-status-tag {
  --bg-color: var(--entity-status-tag-default-bg);
  --text-color: var(--entity-status-tag-default-color);

  display: inline-flex;
  border-radius: 4px;
  line-height: 12px;
  align-items: center;
  padding: 2px 4px;
  color: var(--text-color);
  background-color: var(--bg-color);

  &--active {
    --bg-color: var(--entity-status-tag-active-bg);
    --text-color: var(--entity-status-tag-active-color);
  }

  &--deactivated,
  &--expired {
    --bg-color: var(--entity-status-tag-deactivated-bg);
    --text-color: var(--entity-status-tag-deactivated-color);
  }

  &--muted {
    --bg-color: var(--entity-status-tag-muted-bg);
    --text-color: var(--entity-status-tag-muted-color);
  }

  &__icon {
    display: flex;
    margin-right: 4px;

    .svg-icon {
      --size: 10px;
    }
  }

  &__text {
    font-size: 10px;
    white-space: nowrap;
  }
}
</style>
