<template>
  <div class="widget-empty-list">
    <div class="widget-empty-list__image">
      <img
        alt=""
        :src="imageSrc"
      />
    </div>
    <div class="widget-empty-list__body">
      <slot
        :title="titleText"
      >
        <p>{{ titleText }}</p>
        <b-link
          v-if="isFiltersFilled"
          @click="clearFilter"
        >{{ $vDict('global.button_clear_filter.text') }}</b-link>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'widget-empty-list',
  props: {
    title: { type: String, default: '' },
    isFiltersFilled: { type: Boolean, default: false },
    emptyImageSrc: { type: String, default: '/img/no-items.svg' },
    searchEmptyImageSrc: { type: String, default: '/img/search-empty.svg' },
  },
  computed: {
    titleText() {
      return this.title || this.$vDict('global.text_section_no_items.text');
    },
    imageSrc() {
      return this.isFiltersFilled ? this.searchEmptyImageSrc : this.emptyImageSrc;
    },
  },
  methods: {
    clearFilter() {
      this.$emit('clear-filter');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.widget-empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 60px 15px;

  &__image {
    margin-bottom: 20px;

    img {
      width: 300px;
      max-width: 100%;
    }
  }

  &__body {
    max-width: 300px;
    text-align: center;

    &::v-deep {
      p {
        font-family: var(--font-heading, $font-heading);
        font-size: 18px;
        line-height: 21px;
      }

      a {
        color: var(--secondary);
        font-size: 16px;
        line-height: 19px;
      }

      .create-link {
        display: inline-flex;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 22px;
        font-size: 14px;
        margin-top: 24px;

        &__icon {
          margin-right: 8px;
          display: flex;
          align-items: center;
          height: 22px;

          .svg-icon {
            --size: 16px;

            display: block;
          }
        }
      }
    }
  }
}
</style>
