<template>
  <div
    v-if="isShowLoading"
    :style="sizeStyles"
    :class="loadingClasses"
    @click.stop
  >
    <div class="loading-inner">
      <v-spinner
        :width="iconSize"
        :height="iconSize"
      />
      <div
        v-if="status"
        class="loading-status"
      >
        <div v-html="status"></div>
      </div>
    </div>
  </div>
</template>
<script>
import VSpinner from '@/components/VSpinner';

export default {
  name: 'loading',
  components: {
    VSpinner,
  },
  props: {
    isOverlay: { type: Boolean, default: false },
    isOpacity: { type: Boolean, default: false },
    width: { type: [Number, String], default: 0 },
    height: { type: [Number, String], default: 0 },
    className: { type: String, default: '' },
    statusText: { type: [Object, String] },
    iconSize: { type: [Number, String], default: 40 },
    loadedData: { type: Object, default: null },
    showSpinnerAtTop: { type: Boolean, default: false },
  },
  computed: {
    loadingClasses() {
      const res = ['loading', this.className];

      if (this.isOverlay) {
        res.push('loading--overlay');
      }

      if (this.isOpacity) {
        res.push('opacity');
      }

      if (this.showSpinnerAtTop) {
        res.push('loading--spinner-at-top');
      }

      return res;
    },
    sizeStyles() {
      const res = {};

      if (this.width > 0) {
        res.width = `${this.width}px`;
      }

      if (this.height > 0) {
        res.height = `${this.height}px`;
      }

      return res;
    },
    status() {
      let res = '';

      if (typeof this.statusText === 'string') {
        res = this.statusText;
      } else if (typeof this.statusText === 'object') {
        res = Object.values(this.statusText).join('<br>');
      } else if (this.notLoadedDataValues.length > 0) {
        res = this.notLoadedDataValues.map(el => el.statusText).join('<br>');
      }

      return res;
    },
    notLoadedDataValues() {
      return this.loadedData ?
        Object.values(this.loadedData).filter(el => typeof el.value === 'number' || el.value) : [];
    },
    isShowLoading() {
      return !this.loadedData || !this.notLoadedDataValues.length;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/variables';

  .loading {
    max-height: 100%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &--overlay {
      position: absolute;
      height: auto;
      min-height: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--loading-overlay-bg);
      z-index: 1;
      display: flex;
      align-items: center;
    }

    &--spinner-at-top {
      align-items: flex-start;
    }

    &.opacity {
      background-color: var(--loading-overlay-bg-opacity);
    }

    .loading-inner {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 10px auto 10px;
    }

    .loading-status {
      width: 100%;
      margin: 15px auto 0px;
      padding: 0px 15px;
    }

    &::v-deep {
      .sk-circle {
        margin: 0 auto;
      }
    }
  }
</style>
