<template>
  <select-dropdown-search
    :disabled="disabled"
    :placeholder="placeholder"
    :input-placeholder="inputPlaceholder"
    :value="value"
    :search-func="searchFunc"
    class="select-client"
    @input="$emit('input', $event)"
    @change-selected-item="$emit('change-selected-item', $event)"
  >
    <template v-slot:button-content="{ buttonText, selectedItem }">
      <div class="dropdown-toggle__text">
        <div
          v-if="selectedItem"
          class="selected-client"
        >
          <div class="selected-client__name">{{ selectedItem.name }}</div>
          <div
            v-if="isShowSmartId && selectedItem.id"
            class="selected-client__smart-id"
          >{{ $vDict('forms.select_client_smart_id_label.text') }}: {{ selectedItem.id }}</div>
          <div
            v-if="isShowSourceId && selectedItem.source_id"
            class="selected-client__source-id"
          >{{ $vDict('forms.select_client_source_id_label.text') }}: {{ selectedItem.source_id }}</div>
        </div>
        <template v-else>{{ buttonText }}</template>
      </div>
    </template>
    <template v-slot:search-list="{ listProps, checkValue }">
      <checked-list
        v-bind="listProps"
        @input="checkValue"
      >
        <template v-slot:checked-item="{ item, highlightWord }">
          <div class="client-option">
            <div
              class="client-option__name"
              v-html="highlightWord(item.name)"
            />
            <div
              v-if="isShowSmartId && item.id"
              class="client-option__smart-id"
            >{{ $vDict('forms.select_client_smart_id_label.text') }}: {{ item.id }}</div>
            <div
              v-if="isShowSourceId && item.source_id"
              class="client-option__source-id"
            >{{ $vDict('forms.select_client_source_id_label.text') }}: {{ item.source_id }}</div>
          </div>
        </template>
      </checked-list>
    </template>
  </select-dropdown-search>
</template>

<script>
import Vue from 'vue';
import SelectDropdownSearch from "@/components/forms/SelectDropdownSearch";
import CheckedList from "@/components/forms/checked-lists/CheckedList";
import { getAllClients } from "@/api/repositories/clientsRepository";

export default {
  name: 'select-client',
  components: {
    SelectDropdownSearch,
    CheckedList,
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Number, default: null },
    isShowSmartId: { type: Boolean, default: false },
    isShowSourceId: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default: () => Vue.prototype.$vDict('global.form_select_placeholder.text'),
    },
    inputPlaceholder: {
      type: String,
      default: () => Vue.prototype.$vDict('forms.select_client_input_placeholder.text'),
    },
  },
  data() {
    return {};
  },
  methods: {
    searchFunc: getAllClients,
  },
};
</script>

<style lang="scss" scoped>
.client-option {
  &__source-id,
  &__smart-id {
    font-size: 12px;
    line-height: 14px;
    color: var(--text-shaded);
  }
}

.selected-client {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__name {
    font-size: 14px;
    line-height: 16px;
  }

  &__source-id,
  &__smart-id {
    font-size: 12px;
    line-height: 14px;
    color: var(--text-shaded);
  }

  &__name,
  &__source-id,
  &__smart-id {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
