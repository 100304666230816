<template>
  <div class="c-avatar">
    <img
      v-if="avatarPicture"
      :src="avatarPicture"
      class="c-avatar__img c-avatar__img--image"
    >
    <div
      v-else
      class="c-avatar__img"
    >{{ avatarInitials }}</div>
  </div>
</template>

<script>
export default {
  name: 'avatar',
  props: {
    pic: { type: String, default: '' },
    name: { type: String, default: '' },
    userData: { type: Object, default: Object },
  },
  data() {
    return {};
  },
  computed: {
    avatarPicture() {
      return this.pic || _.get(this.userData, 'pic');
    },
    avatarInitials() {
      let text = this.name;

      if (!text && !_.isEmpty(this.userData)) {
        text = this.getUserText();
      }

      return text ? this.getInitials(text) : '';
    },
  },
  methods: {
    getInitials(text) {
      const arrName = text ? text.split(' ', 2) : [];

      return arrName.reduce((acum, partName) => {
        const symb = partName.substring(0, 1);
        return symb && symb !== '(' ? acum + symb.toUpperCase() : acum;
      }, '');
    },
    getUserText() {
      let res = this.userData.name;

      if (!res && this.userData.email) {
        const emailParts = this.userData.email.split('@');
        res = emailParts[0].split('.').join(' ');
      }

      return res;
    },
  },
};
</script>

<style lang="scss">
.c-avatar {
  --size: 32px;

  width: var(--size);
  height: var(--size);
  color: var(--user-avatar-color);
  font-family: var(--font-heading, $font-heading);
  font-weight: bold;
  font-size: calc(var(--size) / 1.8);
  line-height: 1;
  flex-shrink: 0;

  &__img {
    margin: 0;
    width: 100%;
    height: 100%;
    background: var(--user-avatar-bg);
    transition: 0.3s;
    transition-property: color, background, border-color;
    border: 1px solid var(--user-avatar-border-color);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
