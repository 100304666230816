<template>
  <div
    class="data-table"
    :class="dataTableClasses"
  >
    <div
      v-if="fixedHeader && !hideThead"
      ref="header"
      class="data-table__header"
    >
      <div
        ref="headerScroll"
        class="data-table__header-scroll syncscroll"
      >
        <table
          ref="headerTable"
          class="dt-table"
          data-cy="header-table"
          :style="{ width: cTableWidth }"
        >
          <c-thead
            :fields="tableFields"
            :sort="sort"
            @sort-changed="doSort"
          >
            <template
              v-for="field in tableFields"
              v-slot:[`head(${field.key})`]
            >
              <slot
                :name="`head(${field.key})`"
                :label="field.label"
                :column="field.key"
                :field="field"
              />
            </template>
          </c-thead>
        </table>
      </div>
    </div>

    <div
      ref="dtBody"
      class="data-table__body syncscroll"
    >
      <table
        ref="mainTable"
        class="dt-table"
        data-cy="main-table"
        :class="{ 'dt-table--striped': striped, 'dt-table--lined': lined }"
      >
        <c-thead
          v-show="!hideThead"
          :fields="tableFields"
          :sort="sort"
          :sort-direction="sortDirection"
          @sort-changed="doSort"
        >
          <template
            v-for="field in tableFields"
            v-slot:[`head(${field.key})`]
          >
            <slot
              :name="`head(${field.key})`"
              :label="field.label"
              :column="field.key"
              :field="field"
            />
          </template>
        </c-thead>

        <tbody
          v-if="busy"
          class="dt-table__body"
        >
          <tr
            key="busy"
            class="dt-table__tr dt-table__tr--busy"
          >
            <td
              class="dt-table__td"
              :colspan="tableFields.length"
            >
              <slot name="table-busy" />
            </td>
          </tr>
        </tbody>

        <tbody
          v-else-if="tableItems.length > 0"
          is="transition-group"
          class="dt-table__body"
          name="fade-out"
        >
          <template v-for="(listItem, trIndex) in tableItems">
            <tr
              :key="getRowKey(listItem.originItem)"
              class="dt-table__tr"
              :class="[tbodyTrClass(listItem.originItem), listItem.originItem._rowClass]"
              @click="$emit('click-row', listItem.originItem)"
            >
              <slot
                name="row"
                :item="listItem.originItem"
                :index="trIndex"
                :values="listItem.values"
                :toggle-details="toggleDetails"
              >
                <td
                  v-for="(field, tdIndex) in tableFields"
                  :key="tdIndex"
                  class="dt-table__td"
                  :class="field.class"
                  :data-cy="field.dataCy"
                  :data-source-value="getSourceValue(listItem.originItem, field.sourceKey)"
                >
                  <slot
                    :name="`cell(${field.key})`"
                    :item="listItem.originItem"
                    :index="trIndex"
                    :field="field"
                    :value="listItem.values[field.key]"
                    :toggle-details="toggleDetails(listItem.originItem)"
                  >
                    <template v-if="!hasSlot(`cell(${field.key})`)">{{ listItem.values[field.key] }}</template>
                  </slot>
                </td>
              </slot>
            </tr>
            <template v-if="hasDetailsSlot && listItem.originItem._showDetails">
              <tr
                :key="getRowKey(listItem.originItem, 'details-helper')"
                aria-hidden="true"
                role="presentation"
                class="dt-table__tr--details-helper"
              />
              <tr
                :key="getRowKey(listItem.originItem, 'details')"
                class="dt-table__tr dt-table__tr--details"
                :class="tbodyTrClass(listItem.originItem)"
              >
                <td
                  class="dt-table__td"
                  :colspan="tableFields.length"
                >
                  <slot
                    name="row-details"
                    :item="listItem.originItem"
                    :toggle-details="toggleDetails(listItem.originItem)"
                  />
                </td>
              </tr>
            </template>
          </template>
        </tbody>

        <tbody
          v-else-if="showEmpty"
          class="dt-table__body"
        >
          <tr
            key="empty"
            class="dt-table__tr dt-table__tr--empty"
          >
            <td
              class="dt-table__td"
              :colspan="tableFields.length"
            >
              <slot name="empty" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import MixinSlots from '@/mixins/MixinSlots';
import CThead from '@/components/table/_includes/CThead';
import { visibilityObserver } from '@/shared/utils';

export default {
  name: 'data-table',
  components: {
    CThead,
  },
  mixins: [
    MixinSlots,
  ],
  props: {
    id: { type: [String, Number], default: '' },
    items: { type: Array, default: Array },
    fields: { type: Array, default: Array },
    hideLabels: { type: Array, default: Array },
    sort: { type: Object, default: null }, /*
      {
        sort_key: {
          order: 'a' | 'd',
          priory: 0,
        }
      }
    */
    sortDirection: { type: String, default: 'd' },
    fixedHeader: { type: Boolean, default: false },
    striped: { type: Boolean, default: false },
    busy: { type: Boolean, default: false },
    showEmpty: { type: Boolean, default: false },
    tbodyTrClass: { type: Function, default: () => '' },
    dataset: { type: Object, default: Object },
    hideThead: { type: Boolean, default: false },
    lined: { type: Boolean, default: false },
    fixedRequiredColumns: { type: Boolean, default: false },
    currentPage: { type: Number, default: 0 },
    perPage: { type: Number, default: 0 },
  },
  data() {
    return {
      resizeObserverMainTable: null,
      columnsObserver: null,
      tableWidth: null,
      scroller: null,
      hasScroll: false,
      scrolledHeader: false,
      scrolledBody: false,
      visibleObserver: null,
      isTableVisible: false,
      // bodyHorizontalScrolled: false,
    };
  },
  computed: {
    dataTableClasses() {
      return {
        'data-table--fixed-header': this.fixedHeader,
        'data-table--fixed-required-columns': this.fixedRequiredColumns,
        'data-table--has-scroll': this.hasScroll,
        // 'data-table--horizontal-scrolled': this.bodyHorizontalScrolled,
      };
    },
    cTableWidth() {
      return this.tableWidth ? `${this.tableWidth}px` : null;
    },
    hasDetailsSlot() {
      return this.hasSlot('row-details');
    },
    tableFields() {
      return this.fields
        .filter(field => !field.hidden)
        .map(field => {
          return {
            ...field,
            class: [
              {
                'required-column': field.required,
              },
              field.class,
            ],
          };
        });
    },
    tableItems() {
      let items = [];

      if (this.perPage > 0 && this.currentPage > 0) {
        const fromIndex = (this.currentPage - 1) * this.perPage;
        const toIndex = fromIndex + this.perPage;

        items = this.items.slice(fromIndex, toIndex);
      } else {
        items = this.items;
      }

      return items.map(item => {
        const values = this.tableFields.reduce((acum, field) => ({
          ...acum,
          [field.key]: this.getItemValue(item, field),
        }), {});

        return {
          originItem: item,
          values,
        };
      });
    },
  },
  updated() {
    if (this.debouncedSyncHeaderWidth) {
      this.debouncedSyncHeaderWidth();
    }
  },
  created() {
    this.debouncedSyncHeaderWidth = _.debounce(this.syncHeaderWidth, 10);
  },
  mounted() {
    const { dtBody } = this.$refs;

    if (dtBody && this.fixedHeader) {
      this.resizeMainTableObserver = new ResizeObserver(this.debouncedSyncHeaderWidth);
      this.resizeMainTableObserver.observe(dtBody);

      this.visibleObserver = visibilityObserver(dtBody, (visible, obs) => {
        if (visible) {
          this.isTableVisible = true;
          this.initColumnsObserver();
          this.toggleListenersScrollSync();
          obs.unobserve(dtBody);
        }
      });
    }
  },
  beforeDestroy() {
    if (this.resizeMainTableObserver) {
      this.resizeMainTableObserver.disconnect();
    }

    if (this.columnsObserver) {
      this.columnsObserver.disconnect();
    }

    if (this.visibleObserver) {
      this.visibleObserver.disconnect();
    }

    if (this.fixedHeader) {
      this.toggleListenersScrollSync(true);
    }
  },
  methods: {
    getRowKey(item, prefix = '') {
      return prefix ? `${prefix}_${item.id}` : item.id;
    },
    initColumnsObserver() {
      const { mainTable } = this.$refs;
      const mainThs = mainTable?.querySelectorAll('th.dt-table__th');

      if (!mainTable || !mainThs) {
        return;
      }

      this.columnsObserver = new ResizeObserver(this.debouncedSyncHeaderWidth);

      mainThs.forEach((cell, index) => {
        this.columnsObserver.observe(cell);
      });
    },
    syncHeaderWidth() {
      const { dtBody, mainTable, headerTable } = this.$refs;

      if (!mainTable ||
        !headerTable ||
        dtBody.offsetWidth === 0) {
        return;
      }

      const mainThs = mainTable.querySelectorAll('th.dt-table__th');
      const headerThs = headerTable.querySelectorAll('th.dt-table__th');

      //
      this.tableWidth = dtBody.offsetWidth !== dtBody.scrollWidth ?
        mainTable.offsetWidth : null;

      mainThs.forEach((mainTh, index) => {
        const headTh = headerThs[index];
        const headThWidth = headTh.getBoundingClientRect()?.width;
        const mainThWidth = mainTh.getBoundingClientRect()?.width;

        if (headThWidth !== mainThWidth) {
          // console.log('headTh.offsetWidth', headTh.offsetWidth, mainTh.offsetWidth);
          // console.log('headTh.offsetWidth .getBoundingClientRect()', headTh.getBoundingClientRect(), mainTh.getBoundingClientRect());
          headTh.style.width = `${mainThWidth}px`;
        }
      });

      this.hasScroll = dtBody.scrollWidth > dtBody.clientWidth;
    },
    toggleListenersScrollSync(remove = false) {
      const { dtBody, headerScroll } = this.$refs;
      const action = remove ? 'remove' : 'add';

      dtBody[`${action}EventListener`]('scroll', this.onScrollBody);
      headerScroll[`${action}EventListener`]('scroll', this.onScrollHeader);
    },
    onScrollHeader() {
      if (this.scrolledBody) {
        this.scrolledBody = false;
        return;
      }

      const { scrollLeft } = this.$refs.headerScroll;

      this.scrolledHeader = true;
      this.$refs.dtBody.scrollLeft = scrollLeft;
      // this.bodyHorizontalScrolled = scrollLeft > 0;
    },
    onScrollBody() {
      if (this.scrolledHeader) {
        this.scrolledHeader = false;
        return;
      }

      const { scrollLeft } = this.$refs.dtBody;

      this.scrolledBody = true;
      this.$refs.headerScroll.scrollLeft = scrollLeft;
      // this.bodyHorizontalScrolled = scrollLeft > 0;
    },
    // Factory function methods
    toggleDetails(item) {
      // Returns a function to toggle a row's details slot
      return () => {
        if (this.hasDetailsSlot) {
          this.$set(item, '_showDetails', !item._showDetails);
        }
      };
    },
    getItemValue(item, field) {
      let value;

      if (item.type === 'entity') {
        value = item.getFormattedValue(field.key, this.dataset, this.fields);
      } else {
        value = item[field.key];
      }

      return field.formatter ? field.formatter(value, field.key, item) : value;
    },
    doSort(sort) {
      this.$emit('sort-changed', sort);
    },
    getSourceValue(item, sourceKey) {
      if (!sourceKey) {
        return;
      }

      return item.type === 'entity' ? item.getValue(sourceKey) : item[sourceKey];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
