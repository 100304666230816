<template>
  <div class="v-tooltip">
    <template v-if="actionType === 'links'">
      <div
        v-if="defer(1)"
        :id="containerId"
        class="v-tooltip__container"
        :class="variantClass"
      >
        <ul class="vt-links">
          <li
            v-for="(item, i) in items"
            :key="i"
            class="vt-links__item"
          >
            <b-link
              :id="`${triggerId}-${i}`"
              class="vt-link"
            >
              <div class="vt-link__text">{{ item }}</div>
            </b-link>
          </li>
        </ul>
      </div>
      <template v-if="defer(2) && text">
        <b-tooltip
          v-for="(item, i) in items"
          :key="i"
          ref="tooltip"
          :delay="delay"
          :custom-class="tooltipClass"
          :target="`${triggerId}-${i}`"
          :container="containerId"
          :triggers="triggers"
          :placement="placement"
          :boundary="tooltipBoundary"
        >
          <div class="v-tooltip__body vt">
            <ul class="vt-actions">
              <li
                v-for="(action, i) in linksActions"
                :key="i"
                class="vt-actions__item"
              >
                <b-link
                  class="vt-actions__link"
                  @click="action.func(action.type, item)"
                >
                  {{ action.name }}
                </b-link>
              </li>
            </ul>
          </div>
        </b-tooltip>
      </template>
    </template>
    <template v-else>
      <div
        v-if="defer(1)"
        :id="containerId"
        class="v-tooltip__container"
        :class="variantClass"
      >
        <component
          :is="triggerTag"
          :id="triggerId"
          class="vt-link"
        >
          <slot>
            <div class="vt-link__text">{{ text }}</div>
          </slot>
        </component>
      </div>
      <b-tooltip
        v-if="defer(2) && text"
        ref="tooltip"
        :delay="delay"
        :custom-class="tooltipClass"
        :target="triggerId"
        :container="containerId"
        :triggers="triggers"
        :placement="placement"
        :boundary="tooltipBoundary"
      >
        <div class="v-tooltip__body vt">
          <slot
            name="tooltip-body"
            :do-action="doAction"
          >
            <ul
              class="vt__list"
              :class="{ 'vt__list--actions': actionType !== '' }"
            >
              <li
                v-for="(item, i) in items"
                :key="i"
                class="vt__line"
              >
                <div class="vt__text">
                  <template v-if="actionType === 'email'">
                    <a
                      class="vt__link-email"
                      :href="getMailToLink(item)"
                    >{{ item }}</a>
                  </template>
                  <template v-else>
                    {{ item }}
                  </template>
                </div>
                <div
                  v-if="actionType"
                  class="vt__action"
                >
                  <b-link @click="doAction(actionType, item)">
                    {{ getActionText(actionType) }}
                  </b-link>
                </div>
              </li>
            </ul>
          </slot>
        </div>
      </b-tooltip>
    </template>
  </div>
</template>

<script>
import MixinDefer from '@/mixins/MixinDefer';

export default {
  name: 'v-tooltip',
  mixins: [
    MixinDefer,
  ],
  props: {
    actionType: { type: String, default: '' },
    actions: { type: String, default: '' },
    triggerTag: { type: String, default: 'b-link' },
    placement: { type: String, default: 'top' },
    variant: { type: String, default: 'primary' },
    text: { type: [String, Array], default: '' },
    tooltipClass: { type: String, default: 'v-tooltip__popup' },
    triggers: { type: String, default: 'hover' },
    boundary: { type: String, default: '' },
    boundaryEl: { type: String, default: '' },
    mailParams: { type: Object, default: null },
    localCall: { type: Boolean, default: false },
  },
  data() {
    return {
      delay: {
        show: 200,
        hide: 400,
      },
      triggerId: null,
    };
  },
  computed: {
    items() {
      const typeOfText = typeof this.text;
      return typeOfText === 'object' && Array.isArray(this.text) ? this.text : [this.text];
    },
    typeOfText() {
      return typeof this.text;
    },
    containerId() {
      return `container__${this.triggerId}`;
    },
    variantClass() {
      return `variant-${this.variant}`;
    },
    linksActions() {
      const actions = this.actions.split(' ');
      return actions.map(actionType => {
        return {
          type: actionType,
          name: this.getActionText(actionType),
          func: this.doAction,
        };
      });
    },
    tooltipBoundary() {
      return this.boundary || (this.boundaryEl ? document.querySelector(this.boundaryEl) : '');
    },
  },
  created() {
    this.triggerId = this._uid.toString();
  },
  methods: {
    getMailToLink(item) {
      const link = `mailto:${item}`;
      let linkParams = '';

      if (this.mailParams) {
        linkParams = Object.entries(this.mailParams).reduce((acum, [key, value]) => {
          return value ? [...acum, `${key}=${encodeURIComponent(value)}`] : acum;
        }, []).join('&');
      }

      return linkParams ? `${link}?${linkParams}` : link;
    },
    getActionText(actionType) {
      let res = '';

      switch (actionType) {
        case 'email':
        case 'copy':
          res = this.$vDict('global.text_copy.text');
          break;
        case 'phone':
        case 'call':
          res = this.$vDict('global.text_call.text');
          break;
        default:
      }

      return res;
    },
    doAction(actionType, text) {
      switch (actionType) {
        case 'email':
        case 'copy':
          this.doCopy(text);
          break;
        case 'phone':
        case 'call':
          this.doCall(text);
          break;
        default:
      }
    },
    doCall(number) {
      const resNumber = number.toString()
        .replace(/^00/, '')
        .replace(/ /g, '');

      window.location.href = this.localCall ? `tel:${resNumber}` : `tel:+${resNumber}`;
    },
    doCopy(textToCopy) {
      this.$copyText(textToCopy).then(() => {
        this.$notifToastr('success', this.$vDict('global.text_copy_to_clipboard_success.text', { text: textToCopy }));
      }, () => {
        this.$notifToastr('error', 'Can not copy');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.v-tooltip {
  &__container {
    display: flex;
  }
}

.vt-link {
  display: block;
  text-decoration: none;

  &__text {
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.vt-actions,
.vt-links {
  margin: 0;
  padding: 0;
  list-style: none;
}

.vt-links {
  display: inline-block;
  margin-right: -5px;
  margin-bottom: -4px;

  &__item {
    margin-right: 5px;
    margin-bottom: 4px;

    .vt-link__text::after {
      content: ";";
    }

    &:last-child {
      //margin-right: 0;

      .vt-link__text::after {
        content: normal;
      }
    }
  }
}

.vt-actions {
  display: flex;
  align-items: center;

  &__item {
    font-weight: bold;
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #fff;
    height: 12px;
    display: flex;
    align-items: center;

    &:first-child {
      border-left: none;
      margin-left: 0px;
      padding-left: 0px;
    }
  }
}

.vt {
  &__line {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  &__list {
    list-style: none;
    margin: 0px;
    padding: 0px;

    &--actions {
      text-align: right;
    }
  }

  &__list--actions &__line {
    justify-content: flex-end;
  }

  &__text {
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__action {
    font-weight: bold;
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #fff;
    height: 12px;
    display: flex;
    align-items: center;
  }
}
</style>
