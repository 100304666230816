var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('select-dropdown-search',{staticClass:"select-client",attrs:{"disabled":_vm.disabled,"placeholder":_vm.placeholder,"input-placeholder":_vm.inputPlaceholder,"value":_vm.value,"search-func":_vm.searchFunc},on:{"input":function($event){return _vm.$emit('input', $event)},"change-selected-item":function($event){return _vm.$emit('change-selected-item', $event)}},scopedSlots:_vm._u([{key:"button-content",fn:function(ref){
var buttonText = ref.buttonText;
var selectedItem = ref.selectedItem;
return [_c('div',{staticClass:"dropdown-toggle__text"},[(selectedItem)?_c('div',{staticClass:"selected-client"},[_c('div',{staticClass:"selected-client__name"},[_vm._v(_vm._s(selectedItem.name))]),(_vm.isShowSmartId && selectedItem.id)?_c('div',{staticClass:"selected-client__smart-id"},[_vm._v(_vm._s(_vm.$vDict('forms.select_client_smart_id_label.text'))+": "+_vm._s(selectedItem.id))]):_vm._e(),(_vm.isShowSourceId && selectedItem.source_id)?_c('div',{staticClass:"selected-client__source-id"},[_vm._v(_vm._s(_vm.$vDict('forms.select_client_source_id_label.text'))+": "+_vm._s(selectedItem.source_id))]):_vm._e()]):[_vm._v(_vm._s(buttonText))]],2)]}},{key:"search-list",fn:function(ref){
var listProps = ref.listProps;
var checkValue = ref.checkValue;
return [_c('checked-list',_vm._b({on:{"input":checkValue},scopedSlots:_vm._u([{key:"checked-item",fn:function(ref){
var item = ref.item;
var highlightWord = ref.highlightWord;
return [_c('div',{staticClass:"client-option"},[_c('div',{staticClass:"client-option__name",domProps:{"innerHTML":_vm._s(highlightWord(item.name))}}),(_vm.isShowSmartId && item.id)?_c('div',{staticClass:"client-option__smart-id"},[_vm._v(_vm._s(_vm.$vDict('forms.select_client_smart_id_label.text'))+": "+_vm._s(item.id))]):_vm._e(),(_vm.isShowSourceId && item.source_id)?_c('div',{staticClass:"client-option__source-id"},[_vm._v(_vm._s(_vm.$vDict('forms.select_client_source_id_label.text'))+": "+_vm._s(item.source_id))]):_vm._e()])]}}],null,true)},'checked-list',listProps,false))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }