import Vue from "vue";

export const checkNewItemsByTrigger = Vue.extend({
  data() {
    return {
      configCheckNewItems: {
        intervalDelay: 10000, // 10 sec
        numberAttempts: 4,
      },
      checkNewItemsCounter: 0,
      newItemsTotal: 0,
    };
  },
  computed: {
    isWaitForNewItems() {
      const { selfClass } = this.entityList;
      const triggerListTypeId = selfClass.getEntityProperty('triggerListTypeId');

      if (!triggerListTypeId || !selfClass.isUserSection) {
        return;
      }

      return this.$store.getters.getListWait({
        listTypeId: triggerListTypeId,
      });
    },
  },
  watch: {
    isWaitForNewItems: {
      async handler(val) {
        if (val) {
          this.newItemsTotal = await this.loadTotalNewItems();
          setTimeout(this.checkForNewItems, this.configCheckNewItems.intervalDelay);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async checkForNewItems() {
      const newTotal = await this.loadTotalNewItems();

      if (newTotal <= this.newItemsTotal && this.checkNewItemsCounter <= this.configCheckNewItems.numberAttempts) {
        setTimeout(this.checkForNewItems, this.configCheckNewItems.intervalDelay);
        this.checkNewItemsCounter++;
      } else {
        this.$store.commit('SET_LIST_WAIT', {
          listTypeId: this.entityList.selfClass.getEntityProperty('triggerListTypeId'),
          value: false,
        });
        this.checkNewItemsCounter = 0;
      }

      if (newTotal > this.newItemsTotal) {
        const { statusIdWithNewItems } = this.entityList.selfClass;

        if (this.entityList.statusId === 'all' || this.entityList.statusId === statusIdWithNewItems) {
          await this.entityList.loadItems(1);
          this.entityList.fetchContentStats();
        }
      }
    },
    async loadTotalNewItems() {
      const { statusIdWithNewItems } = this.entityList.selfClass;
      const loadItemsFunc = this.entityList.selfClass.getLoadItemsFunc();
      const statusKey = this.entityList.selfClass.getEntityProperty('statusKey');
      let total = 0;

      if (statusKey && statusIdWithNewItems) {
        const query = {
          filter: {
            [`users.${statusKey}`]: {
              in: [statusIdWithNewItems],
            },
          },
          limit: 0,
        };
        const response = await loadItemsFunc(query);
        total = response?.meta?.total || 0;
      }

      return total;
    },
  },
});
