<template>
  <div
    class="base-dropdown dropdown b-dropdown b-dropdown--link btn-group"
    :class="{ show: visible, 'popup-relative': showPopupRelative }"
  >
    <slot
      name="button"
      :attrs="buttonAttrs"
      :toggle="toggleVisible"
    >
      <b-button
        ref="toggleButton"
        :variant="variant"
        :class="[{ 'dropdown-toggle-no-caret': noCaret }, toggleClass]"
        aria-haspopup="true"
        aria-expanded="false"
        class="dropdown-toggle"
        :disabled="disabled"
        @click="toggleVisible"
      >
        <slot name="button-content"/>
      </b-button>
    </slot>

    <component
      v-if="visible"
      :is="appendToBody ? 'portal' : 'div'"
      to="dropdown-popup"
    >
      <div
        ref="popup"
        :class="popupClass"
        class="base-dropdown__popup dropdown-popup"
      >
        <ul
          v-if="items.length > 0 || likeMenu"
          role="menu"
          tabindex="-1"
          :class="menuClass"
          class="base-dropdown__menu"
          data-cy="dropdown-menu"
        >
          <li
            v-for="(item, i) in items"
            :data-cy="item.dataCy"
            :key="i"
            role="presentation"
            class="base-dropdown__menu-item"
            :class="[{ 'base-dropdown__menu-item--divider': item.type === 'divider' }, item.class]"
          >
            <hr
              v-if="item.type === 'divider'"
              role="separator"
              aria-orientation="horizontal"
              class="dropdown-divider"
            >
            <b-link
              v-else
              class="dropdown-item"
              v-bind="item.linkAttrs"
              :class="[item.linkClass, { active: item.id === value, disabled: item.disabled }]"
              @click="onClickItem(item)"
            >
              <slot
                name="link-content"
                :item="item"
              >{{ item.name }}</slot>
            </b-link>
          </li>

          <slot />
        </ul>
        <slot v-else />
      </div>
    </component>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import { clickOutMixin } from "@/mixins/click-out";
import { focusInMixin } from "@/mixins/focus-in";
import { contains } from "@/shared/dom";

export default {
  name: 'base-dropdown',
  mixins: [
    clickOutMixin,
    focusInMixin,
  ],
  props: {
    right: { type: Boolean, default: false },
    items: { type: Array, default: Array },
    value: { type: Number, default: null },
    menuClass: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    noCaret: { type: Boolean, default: false },
    likeMenu: { type: Boolean, default: false },
    dropleft: { type: Boolean, default: false },
    dropright: { type: Boolean, default: false },
    dropup: { type: Boolean, default: false },
    popupClass: { type: [Array, Object, String] },
    variant: { type: String, default: 'link' },
    appendToBody: { type: Boolean, default: false },
    boundary: { type: [HTMLDivElement, String], default: null },
    noFlip: { type: Boolean, default: false },
    showPopupRelative: { type: Boolean, default: false },
    offset: { type: Array, default: null },
    toggleClass: { type: String, default: '' },
  },
  data() {
    return {
      visible: false,
      popperInstance: null,
    };
  },
  computed: {
    buttonAttrs() {
      return {
        variant: this.variant,
        'aria-haspopup': true,
        'aria-expanded': false,
        class: [
          'dropdown-toggle',
          { 'dropdown-toggle-no-caret': this.noCaret },
        ]
      };
    },
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.$emit('show');

        this.$nextTick(() => {
          const { toggleButton, popup } = this.$refs;
          const modifiers = [
            {
              name: 'offset',
              options: {
                offset: this.offset || [0, 4],
              },
            },
          ];
          let placement = 'bottom';

          if (this.dropleft) {
            placement = 'left';
          } else if (this.dropright) {
            placement = 'right';
          } else if (this.dropup) {
            placement = 'top';
          }

          if (this.right) {
            placement += '-end';
          } else {
            placement += '-start';
          }

          if (this.boundary) {
            modifiers.push({
              name: 'preventOverflow',
              options: {
                boundary: this.boundary,
                padding: 12,
              },
            });
          }

          if (this.noFlip) {
            modifiers.push({
              name: 'flip',
              enabled: false,
            });
          }

          this.popperInstance = createPopper(toggleButton, popup, {
            placement,
            modifiers,
          });

          this.$emit('shown');
        });
      } else if (this.popperInstance) {
        this.$emit('hide');

        setTimeout(() => {
          this.popperInstance.destroy();
          this.popperInstance = null;
          this.$emit('hidden');
        }, 100);
      }

      this.listenForClickOut = visible;
    },
  },
  methods: {
    // Shared hide handler between click-out and focus-in events
    hideHandler(event) {
      const { toggleButton, popup } = this.$refs;
      const { target } = event;

      if (this.visible && !contains(popup, target) && !contains(toggleButton, target)) {
        this.hide();
      }
    },
    // Document click-out listener
    clickOutHandler(event) {
      this.hideHandler(event)
    },
    hide() {
      this.visible = false;
    },
    show() {
      setTimeout(() => {
        this.visible = true;
      });
    },
    toggleVisible() {
      if (this.visible) {
        this.hide();
      } else {
        this.show();
      }
    },
    onClickItem(item) {
      this.$emit('click-item', item);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.base-dropdown {
  $block: &;

  position: static;

  &.popup-relative {
    position: relative;

    #{$block}__popup {
      min-width: 100%;
    }
  }

  &__popup {
    color: var(--text-color);
    -webkit-box-shadow: 0 2px 4px var(--shadow-main);
    box-shadow: 0 2px 4px var(--shadow-main);
    z-index: 1000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: var(--border-radius, $border-radius);
  }

  &__menu {
    list-style: none;
    margin: 0;
    padding: 4px;
    min-width: 160px;

    &::v-deep {
      .dropdown-divider {
        margin: 4px 0;
      }

      .dropdown-item {
        display: flex;
        padding: 8px 12px;
        text-align: left;

        &__icon {
          margin-right: 12px;
          height: 20px;
          display: flex;
          align-items: center;

          .svg-icon {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  &__menu-item--divider:last-child,
  &__menu-item--divider:has(+ &__menu-item--divider) {
    display: none;
  }
}
</style>
