<template>
  <date-picker
    :value="localDateValue"
    :first-day-of-week="2"
    :update-on-input="false"
    v-bind="datePickerOpts"
    class="v-date-picker"
    :class="datePickerClass"
    @input="onInputValue"
  >
    <template v-slot="{ inputValue, inputEvents, placeholder }">
      <label class="vdp-control">
        <span class="vdp-control__icon">
          <svg-icon name="calendar" />
        </span>
        <input
          :value="inputValue"
          :placeholder="placeholder"
          :class="inputClass"
          :disabled="disabled"
          class="vdp-control__input form-control"
          v-on="inputEvents"
        />
        <span class="vdp-control__arrow" />
      </label>
    </template>
  </date-picker>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import calTheme from "@/config/calendar-theme";

const { DateTime } = require('luxon');

export default {
  name: 'v-date-picker',
  components: {
    DatePicker,
  },
  props: {
    params: { type: Object, default: Object },
    value: { type: Object, default: null }, // luxon object
    isInvalid: { type: Boolean, default: false },
    customField: { type: Boolean, default: false },
    size: { type: String, default: 'lg' },
    wait: { type: Boolean, default: false },
    isValid: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      localDateValue: null,
    };
  },
  computed: {
    datePickerOpts() {
      const langIso = this.$getDictLanguage('intlIso');
      const params = _.cloneDeep(this.params);
      const opts = {
        theme: calTheme,
        titlePosition: 'left',
        locale: langIso,
        mode: 'single',
      };

      if (params.maxDate) {
        Object.assign(params, {
          maxDate: params.maxDate.toJSDate(),
        });
      }

      if (params.minDate) {
        Object.assign(params, {
          minDate: params.minDate.toJSDate(),
        });
      }

      if (!params.isInline) {
        Object.assign(opts, {
          popover: {
            placement: 'bottom-start',
            visibility: 'focus',
          },
          inputProps: {
            class: 'form-control',
            // placeholder: DateTime.now()
            //   .setZone(this.$store.getters['Account/getSettingsValue']('user.timezone'))
            //   .setLocale(langIso)
            //   .toFormat(vars.LUXON_FORMAT_SHORT_DATE),
          },
          masks: {
            input: [vars.FORMAT_SHORT_DATE],
          },
        });
      }

      return { ...opts, ...params };
    },
    datePickerClass() {
      return {
        'v-date-picker--invalid': this.isInvalid,
        'v-date-picker--custom-field': this.customField,
        'v-date-picker--disabled': this.disabled,
        'wait': this.wait,
        'is-valid': this.isValid,
        'is-invalid': this.isInvalid,
      };
    },
    inputClass() {
      return {
        'form-control--custom-field': this.customField,
        'wait': this.wait,
        'is-valid': this.isValid,
        'is-invalid': this.isInvalid,
        [`form-control-${this.size}`]: this.size,
      };
    },
    valueStringify() {
      return JSON.stringify(this.value);
    },
  },
  watch: {
    valueStringify: {
      handler() {
        if (!this.value) {
          this.localDateValue = null;
          return;
        }

        this.localDateValue = this.datePickerOpts.mode === 'range' ? {
          start: this.convertValueToDate(this.value?.start),
          end: this.convertValueToDate(this.value?.end),
        } : this.convertValueToDate(this.value);
      },
      immediate: true,
    },
  },
  methods: {
    convertValueToDate(value) {
      return value?.isLuxonDateTime ? new Date(value.toSQLDate().replace(/-/g, "/") + ' 00:00:00') : null;
    },
    convertDateToValue(date) {
      if (!date) {
        return null;
      }

      // To Do: problem with parse '1899-12-31T00:00:00.000Z'
      // const sqlDate = DateTime.fromJSDate(date).toSQLDate() + ' 00:00:00';

      const sqlDate = date.getFullYear() + '-' +
        ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
        ('00' + date.getDate()).slice(-2) + ' 00:00:00';

      return DateTime.fromSQL(sqlDate, { zone: 'utc' });
    },
    onInputValue(value) {
      const res = this.datePickerOpts.mode === 'range' ? {
        start: this.convertDateToValue(value?.start),
        end: this.convertDateToValue(value?.end),
      } : this.convertDateToValue(value);

      if (this.valueStringify !== JSON.stringify(res)) {
        this.$emit('input', res);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-date-picker {
  &::v-deep .vc-popover-content-wrapper {
    z-index: 11;

    @media (max-height: 450px) {
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;

      .vc-popover-caret {
        display: none;
      }
    }
  }

  &--disabled {
    pointer-events: none;

    .vdp-control {
      &__icon {
        color: var(--input-color-disabled);
      }
    }
  }
}

.vdp-control {
  position: relative;
  display: block;
  margin-bottom: 0 !important;
  color: var(--input-color) !important;

  &__icon {
    position: absolute;
    width: 30px;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1px;
    color: currentColor;

    .svg-icon {
      display: block;
      width: 16px;
      height: 16px;
    }
  }

  &__input {
    padding-left: 30px;
    padding-right: 24px;
    font-size: 14px;
  }

  &__arrow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 22px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border: solid currentColor;
      border-width: 0 1px 1px 0;
      margin-top: -4px;
      transform: rotate(45deg);
      opacity: .7;
    }
  }

  .v-date-picker--invalid & {
    --input-color: var(--warning);
    --input-bg: var(--input-invalid-bg);
    --input-focus-bg: var(--input-invalid-bg);
    --input-border-color: var(--input-invalid-border-color);
    --input-focus-border-color: var(--input-invalid-focus-border-color);
  }

  .v-date-picker--custom-field &__icon {
    right: 4px;
    left: auto;
    width: auto;
    padding: 0 5px;
  }

  .v-date-picker--custom-field &__arrow,
  .v-date-picker--invalid &__arrow,
  .v-date-picker--valid &__arrow {
    display: none;
  }

  .v-date-picker--custom-field &__input {
    padding-left: 8px;
  }

  .v-date-picker--custom-field.is-valid &__icon,
  .v-date-picker--custom-field.is-invalid &__icon,
  .v-date-picker--custom-field.wait &__icon {
    display: none;
  }
}
</style>
