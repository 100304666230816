<template>
  <div class="budget-slider">
    <vue-slider
      class="budget-slider__slider"
      :tooltip="showTooltip"
      :tooltip-placement="['top', 'bottom']"
      :min="min"
      :max="max"
      :data="sliderData"
      :value="sliderValue"
      :use-keyboard="true"
      :disabled="disabled"
      @change="onChangeValue"
      @dragging="onDragging"
    >
      <template v-slot:tooltip="{ value }">
        <div
          class="budget-slider__value-tooltip"
          v-html="tooltipLabel(value)"
        />
      </template>

      <template v-slot:dot>
        <button class="vue-slider-dot-handle"/>
      </template>
    </vue-slider>

    <div
      v-if="!disabled"
      class="budget-slider__inputs"
    >
      <div class="bs-input input-budget-value">
        <div class="bs-input__label">
          {{ $vDict('global.text_from.text') }}
        </div>
        <div class="bs-input__field">
          <base-input
            :value="inputsValue.gte.toString()"
            @input="onInputValue('gte', $event)"
          >
            <template #input-append>{{ getCurrencyAbbr(inputsValue.gte) }}</template>
          </base-input>
        </div>
      </div>

      <div class="bs-input input-budget-value">
        <div class="bs-input__label">
          {{ $vDict('global.text_to.text') }}
        </div>
        <div class="bs-input__field">
          <base-input
            :value="inputsValue.lte.toString()"
            @input="onInputValue('lte', $event)"
          >
            <template #input-append>{{ getCurrencyAbbr(inputsValue.lte) }}</template>
          </base-input>
        </div>
      </div>

      <div
        v-if="currencies.length > 0"
        class="bs-input select-currency"
      >
        <div class="bs-input__label">
          {{ $vDict('forms.budget_slider_currency_label.text') }}
        </div>
        <div class="bs-input__field">
          <dropdown-select
            :options="currencies"
            :value="currencyId"
            icon-size="20"
            show-marks
            @input="onInputCurrency"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import BaseInput from "@/components/forms/BaseInput";
import { getCurrencySymbol, formatBudgetValue } from '@/shared/formatters';
import {
  isEmptyValue,
  visibilityObserver,
} from '@/shared/utils';

const DropdownSelect = () => import('@/components/forms/DropdownSelect');

export default {
  name: 'budget-slider',
  components: {
    VueSlider,
    BaseInput,
    DropdownSelect,
  },
  props: {
    value: { type: Array, default: Array },
    disabled: { type: Boolean, default: false },
    showTooltip: { type: String, default: 'always' },
    max: { type: Number, default: 1000 * 1.0e+6 },
    min: { type: Number, default: 0 },
    currencyId: { type: Number, default: null },
    currencies: { type: Array, default: Array },
  },
  data() {
    return {
      step: 1.0e+6,
      transitionIntervalID: null,
      visibleObserver: null,
    };
  },
  computed: {
    defaultValue() {
      return [this.min, this.max];
    },
    sliderValue() {
      const value = this.value || [];
      const res = [];

      this.defaultValue.forEach((val, i) => {
        const resValue = value[i] !== undefined ? value[i] : val;
        res[i] = resValue < this.min ? this.min :
          (resValue > this.max ? this.max : resValue);
      });

      return res;
    },
    inputsValue() {
      return {
        gte: this.sliderValue[0] ? this.sliderValue[0] / this.step : 0,
        lte: this.sliderValue[1] ? this.sliderValue[1] / this.step : 0,
      };
    },
    sliderData() {
      const res = [];

      // 0-10(1), 10-100(10), 100-1000(100)
      for (let i = this.min; i <= this.max;) {
        let stepValue = 0;
        let ostDelStep = 0;

        this.sliderValue.forEach(val => {
          if (val < i && !res.includes(val)) {
            res.push(val);
          }
        });

        res.push(i);

        if (i < 10 * this.step) {
          stepValue = this.step;
        } else if (i < 100 * this.step) {
          stepValue = 10 * this.step;
        } else {
          stepValue = 100 * this.step;
        }

        ostDelStep = i % stepValue;
        i += ostDelStep === 0 ? stepValue : stepValue - ostDelStep;
      }

      if (!res.includes(this.max)) {
        res.push(this.max);
      }

      return res;
    },
    budgetCurrencyId() {
      return this.currencyId || this.$store.getters['Account/getSettingsValue']('user.currency_id');
    },
  },
  mounted() {
    this.$nextTick(() => {
      const slider = this.$el;
      const sliderDots = slider.querySelectorAll('.vue-slider-dot');
      sliderDots.forEach(dot => {
        dot.addEventListener('transitionstart', this.startInterval);
        dot.addEventListener('transitionend', this.stopInterval);
      });
      this.visibleObserver = visibilityObserver(this.$el, visible => {
        if (visible) {
          this.onDragging();
        }
      });
    });
  },
  beforeDestroy() {
    const slider = this.$el;
    const sliderDots = slider.querySelectorAll('.vue-slider-dot');

    sliderDots.forEach(dot => {
      dot.removeEventListener('transitionstart', this.startInterval);
      dot.removeEventListener('transitionend', this.stopInterval);
    });

    if (this.visibleObserver) {
      this.visibleObserver.disconnect();
    }
  },
  methods: {
    tooltipLabel(val) {
      return formatBudgetValue(val, this.min, this.max, this.budgetCurrencyId);
    },
    onDragging() {
      const slider = this.$el;
      const sliderRail = slider.querySelector('.vue-slider-rail');
      const sliderDot = slider.querySelector('.vue-slider-dot');
      const toolTips = slider.querySelectorAll('.budget-slider__value-tooltip');
      const sliderRailWidth = sliderRail.clientWidth;
      const sliderDotWidth = sliderDot.clientWidth;

      toolTips.forEach(tTip => {
        const tooltipWidth = tTip.clientWidth;
        const parentDot = tTip.closest('.vue-slider-dot');
        const sliderDotLeftPos = parentDot.offsetLeft;
        const sliderDotGap = Math.floor((tooltipWidth - sliderDotWidth) / 2);

        let offsetLeft = null;
        if (sliderDotLeftPos - sliderDotGap < 0) {
          offsetLeft = sliderDotGap - sliderDotLeftPos;
        } else if (sliderDotLeftPos + sliderDotGap > sliderRailWidth) {
          offsetLeft = sliderRailWidth - sliderDotLeftPos - sliderDotGap;
        }
        tTip.style.marginLeft = offsetLeft ? `${offsetLeft}px` : null;
      });
    },
    startInterval() {
      this.transitionIntervalID = setInterval(() => {
        this.onDragging();
      }, 20);
    },
    stopInterval() {
      clearInterval(this.transitionIntervalID);
    },
    onChangeValue(val) {
      let res = [val[0]];

      if (val[1] !== vars.CONST_MAX_BUDGET_LIMIT) {
        res.push(val[1]);
      }

      if (res.length === 1 && res[0] === this.min) {
        res = [];
      }

      this.$emit('input', res);
    },
    onInputValue(key, value) {
      const lastSymb = value[value.length - 1];
      const numberValue = parseFloat(value) || 0;
      const maxNumber = this.max / this.step;
      const resValue = numberValue >= maxNumber ? maxNumber : numberValue;

      if (isEmptyValue(value) || lastSymb === '.') {
        return;
      }

      const inputsValue = {
        ...this.inputsValue,
        [key]: resValue,
      };
      const res = {
        gte: inputsValue.gte * this.step,
        lte: inputsValue.lte * this.step,
      };

      if (res.gte <= res.lte && res.gte >= this.min && res.lte <= this.max) {
        this.onChangeValue([res.gte, res.lte]);
      }
    },
    getCurrencyAbbr(value) {
      const currencyValue = value * this.step;
      let res = getCurrencySymbol(this.budgetCurrencyId, this.step).symbolWithCompact;

      if (currencyValue === vars.CONST_MAX_BUDGET_LIMIT) {
        res += '+';
      }

      return res;
    },
    onInputCurrency(currencyId) {
      this.$emit('change-currency', currencyId);
    },
  },
};
</script>

<style lang="scss" scoped>
.budget-slider {
  &__slider {
    margin: 50px 7px 32px;

    &:last-child {
      margin-bottom: 62px;
    }
  }

  &__value-tooltip {
    white-space: nowrap;
    position: absolute;
    left: 0;
    transform: translateX(-50%);
  }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
  }

  &.vue-slider-disabled {
    cursor: default;
  }

  .bs-input {
    flex: 1;
    max-width: 130px;
    margin-right: 16px;
    margin-bottom: 12px;

    &:last-child {
      margin-right: 0;
    }

    &__label {
      font-weight: bold;
      margin-bottom: 5px;
    }

    &.select-currency {
      max-width: 210px;
    }
  }

  .vue-slider-dot-tooltip-top &__value-tooltip {
    bottom: 0;
  }

  .vue-slider-dot-tooltip-bottom &__value-tooltip {
    top: 0;
  }
}
</style>
