<template>
  <div class="field-user-selections">
    <ul class="selections-list">
      <li
        class="selections-list__item"
        v-for="selection in selections"
      >
        <div class="selections-list__name">{{ selection.name }}</div>
        <b-link
          @click.prevent="showEditUserSelectionFormModal(selection)"
        >
          <svg-icon
            class="selections-list__link"
            size="16"
            name="popup"
          />
        </b-link>
      </li>
    </ul>

    <edit-user-selection-modal
      ref="editUserSelectionModal"
      :selection="selectedItem"
    />
  </div>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import EditUserSelectionModal from '@/entities/user/views/EditUserSelectionModal';

export default {
  name: 'field-user-selections',
  components: {
    BaseModal,
    EditUserSelectionModal,
  },
  props: {
    selections: { type: Array, default: Array },
  },
  data() {
    return {
      selectedItem: {},
    }
  },
  methods: {
    showEditUserSelectionFormModal(item) {
      this.selectedItem = item;
      this.$refs.editUserSelectionModal.show();
    }
  }
}
</script>

<style lang="scss" scoped>
.field-user-selections {
  display: table;

  .selections-list {
    list-style: none;
    padding: 0;

    &__item {
      display: table;
      width: 100%;
      margin: 0 12px 12px 0;
    }

    &__name, &__link {
      display: table-cell;
      vertical-align: middle;
    }
  }
}
</style>
