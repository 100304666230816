<template>
  <div
    class="collapsible-list-item"
    :class="{
      'collapsible-list-item--visible': visible,
      'collapsible-list-item--collapsible': isCollapsible,
    }"
  >
    <div
      v-if="isSelectable"
      class="collapsible-list-item__selector"
    >
      <b-form-checkbox
        v-model="checkedValue"
        :value="item.value"
        switch
        class="collapsible-list-item__checkbox"
      />
    </div>

    <div class="collapsible-list-item__body item-info">
      <template v-if="isCollapsible">
        <div
          v-b-toggle="`cl-item-${clId}`"
          class="item-info__header"
        >
          <div class="item-info__icon">
            <svg-icon name="chevron-right" />
          </div>
          <div class="item-info__title">{{ item.name }}</div>
        </div>
        <b-collapse
          v-model="visible"
          :id="`cl-item-${clId}`"
          @show="onShown"
        >
          <div
            v-if="visible"
            class="item-info__content"
          >
            <slot />
          </div>
        </b-collapse>
      </template>
      <template v-else>
        <div class="item-info__header">
          <div class="item-info__icon">
            <svg-icon name="chevron-right" />
          </div>
          <div class="item-info__title">{{ item.name }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import MixinSlots from "@/mixins/MixinSlots.vue";

export default {
  name: 'collapsible-list-item',
  mixins: [
    MixinSlots,
  ],
  props: {
    item: {
      type: Object,
      default: Object,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      clId: this._uid,
      visible: false,
      isCollapsible: false,
    };
  },
  computed: {
    checkedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    this.isCollapsible = !!this.hasSlot();
  },
  methods: {
    onShown() {
      this.$emit('shown', this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.collapsible-list-item {
  background-color: var(--ca-expnd-bg);
  border-bottom: 1px solid var(--line-color-main);
  transition: 0.3s ease-in-out;
  transition-property: background, border;
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;

  &__body {
    flex: 1;
    min-width: 0;
  }

  &__selector {
    padding: 8px;
  }
}

.item-info {
  &__header {
    padding: 9px 8px;
    display: flex;

    .collapsible-list-item--collapsible & {
      cursor: pointer;
    }
  }

  &__icon {
    color: var(--secondary);
    width: 18px;
    height: 18px;
    margin-right: 8px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    transition-property: transform, color;
    opacity: 0;

    .svg-icon {
      width: 12px;
      height: 12px;
    }

    .collapsible-list-item--visible & {
      transform: rotate(90deg);
    }

    .collapsible-list-item--collapsible & {
      opacity: 1;
    }
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    align-self: center;
    display: flex;
    font-weight: 500;
  }

  &__content {
    margin-bottom: 30px;
    padding: 0 12px;
  }
}
</style>
