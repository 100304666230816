<template>
  <li class="checkboxes-related-column">
    <div class="checkboxes-related-column__mob-nav">
      <a
        class="crl-back-btn"
        @click="resetActiveItem"
      >
        <span class="crl-back-btn__text">{{ $vDict('global.text_back_to_category.text') }}</span>
      </a>
    </div>

    <ul class="checkboxes-related-column__list">
      <li
        is="checkboxes-related-item"
        v-for="row in columnRows"
        :key="row.item._id"
        :value="row.value"
        :item="row.item"
        :is-exclude="isExclude"
        :disabled="disabled"
        :active-item="activeItem"
        :view-only="viewOnly"
        :is-mobile="isMobile"
      />
    </ul>
  </li>
</template>

<script>
import CheckboxesRelatedItem from './CheckboxesRelatedItem';

export default {
  name: 'checkboxes-related-column',
  components: {
    CheckboxesRelatedItem,
  },
  props: {
    items: { type: Array, default: Array },
    value: { type: Array, default: Array },
    isExclude: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    viewOnly: { type: Boolean, default: false },
    activeItem: { type: Object, default: null },
    isMobile: { type: Boolean, default: false },
  },
  computed: {
    columnRows() {
      return this.items.map(item => {
        return {
          item,
          value: item._leafChildrenIds.length > 0 ?
            this.value.filter(value => {
              const val = this.isExclude ? Math.abs(value) : value;
              return item._leafChildrenIds.includes(val);
            }) : [],
        };
      });
    },
  },
  inject: {
    setActiveItem: { default: () => {} },
  },
  methods: {
    resetActiveItem() {
      this.setActiveItem(null);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkboxes-related-column {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1 1;
  }

  &:not(.active-col) &__list {
    max-height: 250px;
    padding: 0 0 5px;
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
  }

  .checkboxes-related.is-mobile & {
    display: none;
    margin-right: 0;

    &:not(:first-child) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: 50;
      padding: 7px 15px 15px;
      display: flex;
      flex-direction: column;
      transform: translateX(-100%);
      transition: transform 0.3s;
      min-height: auto;
      overflow-y: auto;
      -webkit-overflow-scrolling: auto;

      &.active-col {
        transform: translateX(0);
      }
    }

    //.dashboard-container &:not(:first-child) {
    //  top: 55px;
    //  height: calc(100% - 55px);
    //}

    //.search-panel &:not(:first-child) {
    //  top: 0;
    //  height: calc(100% - 15px);
    //}

    &:not(:first-child) &__body {
      min-height: 0;
      max-height: none;
      flex: 1 1;
    }

    &:first-child {
      display: block;

      .checkboxes-related-column__mob-nav {
        display: none;
      }
    }
  }

  &__mob-nav {
    border-bottom: 1px solid var(--line-color-main);
    color: var(--text-color);
    padding-bottom: 2px;
    margin-bottom: 5px;

    @include media-breakpoint-up(sm) {
      display: none;
    }

    .crl-back-btn {
      font-family: var(--font-heading, $font-heading);
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 10px 0;
      cursor: pointer;
      display: inline-flex;
      align-items: center;

      &:not([href]):not([tabindex]):hover {
        color: var(--secondary, $secondary);
      }

      &::before {
        content: '';
        display: block;
        color: var(--secondary, $secondary);
        margin-top: -2px;
        margin-right: 5px;
        width: 8px;
        height: 8px;
        border: solid currentColor;
        border-width: 0 0 1px 1px;
        transform: rotate(45deg);
      }
    }
  }
}
</style>
