<template>
  <thead class="dt-table__head">
    <tr class="dt-table__head-tr">
      <th
        v-for="(field, index) in thFields"
        :key="index"
        class="dt-table__th"
        :class="field.class"
        :data-cy="field.dataCy"
        @click="onClick(field)"
      >
        <div class="dt-head-label">
<!--       <div
            v-if="iconsVisible && field.labelIcon"
            class="dt-head-label__icon"
          >
            <svg-icon
              :name="field.labelIcon"
            />
          </div>-->
          <div class="dt-head-label__body">
            <div class="dt-head-label__text"><slot :name="`head(${field.key})`">{{ field.label }}</slot></div>
            <div
              v-if="field.sortable"
              class="dt-head-label__sort"
            >
              <template v-if="sort[field.key] && isSortFirst(field.key)">
                <svg-icon
                  v-show="sort[field.key].order === 'a'"
                  name="sort-up-sm"
                />
                <svg-icon
                  v-show="sort[field.key].order === 'd'"
                  name="sort-down-sm"
                />
              </template>
              <svg-icon
                v-else
                name="sort-none-sm"
              />
            </div>
          </div>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
import MixinSlots from '@/mixins/MixinSlots';

export default {
  name: 'c-thead',
  mixins: [
    MixinSlots,
  ],
  props: {
    fields: { type: Array, default: Array },
    sort: { type: Object, default: null },
    sortDirection: { type: String, default: 'd' },
    iconsVisible: { type: Boolean, default: false },
  },
  data() {
    return {
      // sortBy: null,
      // sortDesc: false,
    };
  },
  computed: {
    thFields() {
      return this.fields.map(field => ({
        ...field,
        class: [
          {
            'dt-table__th--sortable': field.sortable,
          },
          field.class,
        ],
        label: _.has(field, 'label') ? field.label : field.key,
      }));
    },
  },
  methods: {
    onClick(field) {
      if (field.sortable) {
        const sort = {
          [field.key]: {
            order: 'd',
            // priory: 0,
          },
        };

        if (_.has(this.sort, field.key)) {
          sort[field.key].order = this.sort[field.key].order === 'a' ? 'd' : 'a';
        } else {
          sort[field.key].order = this.sortDirection;
        }

        this.$emit('sort-changed', sort);
      }
    },
    isSortFirst(sortKey) {
      const sortKeys = this.sort ? Object.keys(this.sort) : [];
      return sortKeys[0] && sortKeys[0] === sortKey;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
