<template>
  <component
    :is="checkGroupComponent"
    v-if="items.length > 0"
    class="checked-list"
    :checked="checked"
    @change="onChangeValue"
  >
    <ul
      data-cy="checked-items"
      class="checked-items"
    >
      <li
        v-for="item in items"
        :key="item.id"
        data-cy="checked-item"
        class="checked-items__item checked-item"
        :class="{ 'active': isChecked(item), 'disabled': item.disabled }"
      >
        <component
          :is="checkComponent"
          :value="item.id"
          :disabled="item.disabled"
          data-cy="checked-item-checkbox"
        >
          <slot
            name="checked-item"
            :item="item"
            :highlightWord="highlightWord"
          >
            <div v-html="getItemText(item)" />
          </slot>
        </component>
      </li>
    </ul>
  </component>
</template>

<script>
import { getSearchRegex } from "@/shared/utils";

export default {
  name: 'checked-list',
  props: {
    checked: { type: [Number, Array], default: null },
    items: { type: Array, default: Array },
    searchText: { type: String, default: '' },
    isMultiple: { type: Boolean, default: false },
    formatter: { type: Function, default: null },
    nameKey: { type: String, default: 'name' },
  },
  data() {
    return {};
  },
  computed: {
    checkGroupComponent() {
      return this.isMultiple ? 'b-form-checkbox-group' : 'b-form-radio-group';
    },
    checkComponent() {
      return this.isMultiple ? 'b-form-checkbox' : 'b-form-radio';
    },
  },
  methods: {
    isChecked(item) {
      let isChecked = false;

      if (this.isMultiple) {
        isChecked = this.checked && this.checked.includes(item.id);
      } else {
        isChecked = item.id === this.checked;
      }

      return isChecked;
    },
    highlightWord(text) {
      return text ? text.replace(getSearchRegex(this.searchText), '<b>$1</b>') : '';
    },
    getItemText(item) {
      const formattedText = this.formatter ? this.formatter(item) : item[this.nameKey];
      return this.highlightWord(formattedText);
    },
    onChangeValue(checked) {
      this.$emit('input', checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.checked-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.checked-item {
  &::v-deep {
    .custom-radio, .custom-checkbox {
      display: flex;
      margin: 0;
      padding: 0;
    }
    .custom-control-label {
      display: flex;
      flex: 1;
      font-size: 14px;
      line-height: 20px;
      padding: 10px;

      b {
        font-weight: bold;
      }
    }

    &.active .custom-control-label,
    &:not(.disabled) .custom-control-label:hover {
      background-color: var(--user-select-bg, $user-select-bg);
    }

    .custom-checkbox .custom-control-label {
      padding-right: 32px;

      &::before, &::after {
        position: absolute;
        left: auto;
        right: 8px;
        top: calc(50% - 8px);
      }
    }

    .custom-radio .custom-control-label {
      &::before,
      &::after {
        content: normal;
      }
    }

    .c-avatar {
      --size: 28px;
    }
  }

  &__avatar {
    flex: none;
    margin-right: 7px;
  }

  &__name {
    min-width: 0;
    padding-top: 1px;
    flex: 1;
    align-self: center;
  }

  &.active .c-avatar__img {
    color: var(--avatar-active-color, $avatar-active-color);
    background: var(--avatar-active-bg, $avatar-active-bg);
    border-color: var(--avatar-active-border-color, $avatar-active-border-color);
  }
}

.user-option {
  display: flex;
  align-items: center;

  &__avatar {
    flex-shrink: 0;
    margin-right: 8px;
  }

  /*&__name {
    font-weight: 500;
  }*/

  &__email {
    font-size: 12px;
    line-height: 14px;
  }

  &::v-deep {
    b {
      color: var(--text-color);
    }
  }
}
</style>
