const calTheme = {
  color: 'brand',
  isDark: false,
  container: {
    light: 'c-date-picker',
  },
  header: {
    light: 'c-date-picker__header',
  },
  title: {
    light: 'c-date-picker__title',
  },
  arrows: {
    light: 'c-date-picker__arrows',
  },
  weekdays: {
    light: 'c-date-picker__weekdays',
  },
  navPopoverContainer: {
    light: 'c-date-picker__popover',
  },
  navTitle: {
    light:
      'vc-text-{color}-100 vc-font-bold vc-leading-snug vc-px-2 vc-py-1 vc-rounded vc-border-2 vc-border-transparent hover:vc-bg-gray-900 focus:vc-border-{color}-600',
  },
  navArrows: {
    light:
      'vc-leading-snug vc-rounded vc-border-2 vc-border-transparent hover:vc-bg-gray-900 focus:vc-border-{color}-600',
  },
  navCell: {
    light:
      'vc-w-12 vc-font-semibold vc-cursor-pointer vc-text-center vc-leading-snug vc-py-1 vc-rounded vc-border-2 vc-border-transparent hover:vc-bg-gray-900 hover:vc-shadow-inner hover:vc-text-white focus:vc-border-{color}-600',
  },
  navCellInactive: 'vc-border-transparent',
  navCellInactiveCurrent: {
    light: 'vc-text-{color}-100 vc-font-bold vc-border-{color}-100',
  },
  navCellActive: {
    light: 'vc-bg-{color}-100 vc-text-{color}-900 vc-border-transparent vc-font-bold vc-shadow',
  },
  dayNotInMonth: 'vc-other-month vc-pointer-events-none',
  dayContent: 'vc-text-sm vc-cursor-pointer vc-rounded-full',
  dayContentDisabled: {
    light: 'vc-day-disabled vc-pointer-events-none',
  },
  dayPopoverContainer: {
    light:
      'vc-rounded vc-text-xs vc-text-white vc-font-medium vc-bg-gray-800 vc-border vc-border-gray-700 vc-px-2 vc-py-1 vc-shadow',
  },
  dayPopoverHeader: {
    light: 'vc-text-xs vc-text-gray-300 vc-font-semibold',
  },
  highlightBaseFillMode: 'light',
  highlightStartEndFillMode: 'solid',
  highlightStartEndClass: 'vc-rounded-full',
  bgLow: {
    light: 'vc-border-2 vc-border-{color}',
  },
  bgAccentLow: {
    light: 'vc-bg-range',
  },
  bgAccentHigh: {
    light: 'vc-bg-highlight',
  },
  contentAccent: {
    light: 'vc-text-{color}-900',
  },
  contentAccentContrast: 'vc-text-white',
  datePickerInput:
    'vc-appearance-none vc-text-base vc-text-gray-800 vc-bg-white vc-border vc-border-gray-400 vc-rounded vc-w-full vc-py-2 vc-px-3 vc-leading-tight focus:vc-outline-none focus:vc-shadow',
  datePickerInputDrag:
    'vc-appearance-none vc-text-base vc-text-gray-500 vc-bg-white vc-border vc-border-gray-400 vc-rounded vc-w-full vc-py-2 vc-px-3 vc-leading-tight focus:vc-outline-none focus:vc-shadow',
};

export default calTheme;
